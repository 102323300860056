import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useGetSingleProgram} from 'api/Classes/useGetSingleProgram';
import {getLocationBySiteId} from 'constants/locations';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {PageHeading} from 'components/Connects/PageHeading';
import {EnrollmentCard} from 'components/Programs/EnrollmentCard';
import {Button} from 'components/Connects/Button';
import {Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {ProgramFilters, useProgramFiltering} from 'components/Programs/ProgramFilters';
import {Modal} from 'components/Modal/Modal';
import {ProgramEmptyDayForm} from 'components/Programs/ProgramEmptyDayForm';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {useGetSport} from 'common/useGetSport';
import {useAppSelector} from 'redux/reduxUtils';

export const ProgramClassSchedulesList = () => {
  const navigate = useNavigate();
  const {siteId, programId} = useParams();
  const {isFreeTrialClasses} = useGetSport();
  const [showFilters, setShowFilters] = useState(false);
  const [contactFormData, setContactFormData] = useState<Enrollment | ClassSchedule | null>();

  const {data: program, isLoading: isLoadingProgram} = useGetSingleProgram({siteId, programId});
  const {programFiltering} = useProgramFiltering();
  const openContactForm = useCallback(
    ({enrollment}: {enrollment: Enrollment | ClassSchedule | null}) => setContactFormData(enrollment),
    []
  );
  const {userData} = useAppSelector((state) => state.fkoFormDataReducer);
  const {basePath} = useGetSport();

  const partialBookingUrl = `${basePath}/${siteId}/${programId}`;
  const locationName = getLocationBySiteId(siteId ?? '')?.name;
  const pageHeading = isFreeTrialClasses ? 'Free Trial Classes' : program?.name ?? 'Our Classes';

  useEffect(() => {
    if (!userData) {
      navigate(basePath, {replace: true});
    }
  }, [basePath, navigate, userData]);

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Program Listings</title>
      </Helmet>

      <Modal isOpen={!!contactFormData} onClose={() => setContactFormData(null)} showHeader>
        <ProgramEmptyDayForm enrollment={contactFormData} />
      </Modal>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar />
        <Breadcrumbs data={{program}} />

        {isLoadingProgram ? (
          <LoadingAnimationFullHeight />
        ) : program ? (
          <>
            <div className="flex justify-center text-center">
              <PageHeading text={pageHeading} />
            </div>

            <div className="flex flex-col gap-6 md:gap-8 lg:mx-auto max-w-[1024px]">
              <p className="font-poppins text-base text-blue-dark text-center mx-auto max-w-[800px]">
                {program.description}
              </p>

              <div className="flex shrink mx-auto md:hidden">
                <Button text="Filters" color="secondary" onClick={() => setShowFilters((prev) => !prev)} />
              </div>

              <div className="grow min-h-full flex flex-col md:flex-row md:gap-8 items-center md:items-start">
                {/* always show Filters on desktop, use showFilters state on mobile */}
                {program && (
                  <>
                    <div className="hidden md:flex w-[208px] min-w-[208px] lg:w-3/12 flex-col">
                      <ProgramFilters program={program} />
                    </div>
                    <div
                      className={`flex md:hidden w-full xs:px-4 sm:px-12 mb-8 flex-col ${
                        showFilters ? 'max-h-[1200px]' : 'max-h-0 overflow-hidden'
                      } transition-all`}
                    >
                      <ProgramFilters program={program} />
                    </div>
                  </>
                )}

                {program.classSchedules?.length ? (
                  <ul className="grow flex flex-col gap-6">
                    {program.classSchedules
                      .filter(programFiltering)
                      .sort((a, b) =>
                        a.name && b.name && a.name !== b.name
                          ? a.name < b.name
                            ? -1
                            : 1
                          : (a.schedule.startTime?.slice(0, 2) as any) - (b.schedule.startTime?.slice(0, 2) as any)
                      )
                      .map((classSchedule, i) => (
                        <li key={`enroll_list_card_${i}`} className="">
                          <EnrollmentCard
                            classSchedule={classSchedule}
                            locationName={locationName}
                            partialBookingUrl={partialBookingUrl}
                            openContactForm={openContactForm}
                          />
                        </li>
                      ))}
                  </ul>
                ) : (
                  <div className="grow grid place-content-center md:mt-3">
                    <p className="text-base text-primary text-center px-5 py-3 rounded border border-grey md:border-primary md:shadow-dispersed md:bg-white">
                      There are no sessions running right now, check back soon!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>
            {/* TODO CLASSES make better */}
            <p>Unable to load program data</p>
          </div>
        )}
      </div>
    </>
  );
};
