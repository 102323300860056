import {useQuery} from '@tanstack/react-query';
import {getAllVisits, GetAllVisitsParams} from 'api/api';
import {TocaLocation} from 'constants/locations';

export type Visit = {
  Action: string;
  AppointmentGenderPreference: string;
  AppointmentId: number;
  AppointmentStatus: string;
  ClassId: number;
  ClientId: string;
  EndDateTime: string;
  Id: number;
  LastModifiedDateTime: string;
  LateCancelled: boolean;
  LocationId: number;
  MakeUp: boolean;
  Missed: boolean;
  Name: string;
  ProductId: number | null;
  ServiceId: number | null;
  ServiceName: number | null;
  SignedIn: boolean;
  SiteId: number;
  StaffId: number;
  StartDateTime: string;
  TypeGroup: number;
  TypeTaken: any;
  VisitType: number;
  WebSignup: boolean;
};

export type FutureVisit = Visit & {
  cancellable: boolean;
  location?: TocaLocation;
};

export const generateVisitsQueryKey = ({clientId}: {clientId?: string | null}) => {
  return ['player', 'sessions', clientId];
};

export const useGetMboVisits = ({clientId, siteId}: GetAllVisitsParams) => {
  return useQuery<Visit[]>({
    queryKey: generateVisitsQueryKey({clientId}),
    queryFn: () => getAllVisits({clientId, siteId}),
    enabled: !!clientId,
    retry: (count, error) => {
      if (error === 'Invalid parameters') {
        return true;
      }
      if (count > 1) {
        return false;
      }
      return true;
    },
  });
};
