import {PropsWithChildren} from 'react';
import {useProfiles} from '../../api/User/useProfiles';
import {isCoach} from 'user/user-utils';

export default function CoachAuthContainer({children}: PropsWithChildren) {
  const {currentProfile} = useProfiles();

  if (!isCoach(currentProfile)) {
    return <div>Insufficient permissions</div>;
  }

  return <>{children}</>;
}
