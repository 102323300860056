import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {useForm} from 'react-hook-form';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {ReactComponent as EditIcon} from '../../../assets/img/icons/edit-icon-minimal.svg';
import {teamColors} from 'constants/teamColors';
import TeamColorSelection from 'components/TeamColorSelection/TeamColorSelection';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import {useUpdateTeam} from 'api/Coach/useUpdateTeam';
import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';

type FormTypes = {
  teamName: string;
};

const validationSchema = yup.object().shape({
  teamName: yup.string().required('Team name is required'),
});

const EditTeam = () => {
  const navigate = useNavigate();
  const {teamId} = useParams();
  const queryClient = useQueryClient();
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [editTeamName, setEditTeamName] = useState<boolean>(false);
  const [showTeamColorSelectionModal, setShowTeamColorSelectionModal] = useState<boolean>(false);
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);
  const team = coachInfo?.teams.find((team) => team._id === teamId);

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onTeamUpdateSuccessHandler = () => {
    queryClient.invalidateQueries({queryKey: ['coach-info', coachInfo?.coachDetails._id]});
    toast.success('Team updated successfully.');
    setEditTeamName(false);
  };

  const onTeamUpdateErrorHandler = () => {
    toast.success('Failed to update team.');
  };

  const {updateTeam} = useUpdateTeam(onTeamUpdateSuccessHandler, onTeamUpdateErrorHandler);

  useEffect(() => {
    if (team?.teamColor) {
      setSelectedColor(team.teamColor);
    }
    if (team?.teamName) {
      setValue('teamName', team?.teamName);
    }
  }, [team, setValue]);

  if (!team) {
    return <div>Team not found</div>;
  }

  const onClickCancelHandler = () => {
    navigate('/coach/team');
  };

  const onSubmitHandler = async (data: FormTypes) => {
    const requestPayload = {_id: team._id, teamName: data.teamName, teamColor: selectedColor};
    updateTeam(requestPayload);
  };

  return isLoading ? (
    <div className="w-full h-full flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="flex-1 flex flex-col w-auto items-center mt-20 mb-8">
      <img
        src={team.logoUrl}
        alt={`${team.teamName} logo`}
        className="h-32 w-32 min-w-32 rounded-full border-4 border-grey"
      />

      <div className="mt-7 w-56">
        <div className="text-sm flex items-center justify-between">
          Team&apos;s Name
          {!editTeamName && (
            <EditIcon className="fill-grey-xdark h-4 w-4 cursor-pointer mb-1" onClick={() => setEditTeamName(true)} />
          )}
        </div>
        {editTeamName ? (
          <>
            <StandardInput
              spellCheck="false"
              type="text"
              id="teamName"
              placeholder="Team Name"
              {...register('teamName')}
            />
            {errors.teamName?.message && <ErrorMessage text={errors.teamName?.message} />}
          </>
        ) : (
          <div className="text-lg text-primary font-semibold border-b border-grey mt-4">{team.teamName}</div>
        )}
      </div>
      <div className="mt-7 w-56">
        <div className="text-sm flex items-center justify-between border-b border-grey pb-1">
          <div className="flex items-center">
            Color <div className="w-4 h-4 rounded-sm ml-5" style={{backgroundColor: selectedColor}} />
          </div>
          <EditIcon
            className="fill-grey-xdark h-4 w-4 cursor-pointer mb-1"
            onClick={() => setShowTeamColorSelectionModal(true)}
          />
        </div>
        <div className="flex flex-wrap mt-4">
          {teamColors.map((color) => (
            <div className="w-6 h-6 rounded-sm ml-0 mt-1 mr-2 mb-1" style={{backgroundColor: color}} key={color} />
          ))}
        </div>
        <TeamColorSelection
          isOpen={showTeamColorSelectionModal}
          closeModal={() => setShowTeamColorSelectionModal(false)}
          activeColor={team.teamColor}
          onColorSelectionHandler={setSelectedColor}
        />
      </div>

      <div className="flex mt-24 w-80 items-center">
        <Button type="submit" text="Update" color="secondary" variant="cta" />
        <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={onClickCancelHandler} />
      </div>
    </form>
  );
};

export default EditTeam;
