import styles from './ProfileBar.module.scss';
import avatar from '../../assets/img/avatar-profile.png';
import {ReactComponent as EmailIcon} from '../../assets/img/icons/redesign-icons/send-mail.svg';
import {AthleteInfo} from 'api/Trainer/useGetAthleteInfo';
import {PlayerDetails} from 'api/User/useGetPlayerDetails';

type ProfileBarProps = {
  athleteInfo?: AthleteInfo;
  playerDetails?: PlayerDetails;
  teamColor?: string;
};

export const ProfileBar = ({athleteInfo, playerDetails, teamColor}: ProfileBarProps) => {
  if (!athleteInfo && !playerDetails) {
    return null;
  }

  const profileName = playerDetails?.fullName ?? `${athleteInfo?.firstName} ${athleteInfo?.lastName}`;
  const email = playerDetails?.email ?? athleteInfo?.email;

  // If the player is part of any team, set the team color; otherwise, set the default color.
  const profileBarBackgroundColor = teamColor || athleteInfo?.playerTeam?.teamColor || 'var(--theme-primary)';

  return (
    <div className={styles.container} style={{backgroundColor: profileBarBackgroundColor}}>
      <div className={styles.avatar}>
        <img src={avatar} />
      </div>

      {/* <h2>{`${athleteInfo.firstName} ${athleteInfo.lastName}`}</h2> */}
      {/* <div className={styles.infoSection}>
          <div className={`${styles.stats} ${styles[athleteInfo.currentLevel]}`}>
            <div className={styles.column}>
              <p className={styles.infoHeading}>Pathway</p>
              <p className={styles.info}>{athleteInfo.currentLevel}</p>
            </div>
            <div className={styles.column}>
              <p className={styles.infoHeading}>Age</p>
              <p className={styles.info}>{athleteInfo.age}</p>
            </div>
            <div className={styles.column}>
              <p className={styles.infoHeading}>Center</p>
              <p className={styles.info}>{athleteInfo.locationName}</p>
            </div>
          </div> */}
      <div className={styles.profileInfo}>
        <p className={styles.name}>{profileName}</p>

        <div className={styles.contact}>
          <p className={styles.email}>
            {/* <span>myTOCA&nbsp;ID:&nbsp;</span> */}
            {email}
          </p>
          {athleteInfo ? (
            <button
              className={styles.contactButton}
              // onClick={() => {
              //   console.log('ding');
              // }}
            >
              <EmailIcon className={styles.icon} />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
