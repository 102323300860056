import {FormButton} from '../../components/Forms/FormButton/FormButton';
import {memo, useCallback, useEffect, useState} from 'react';
import {FkoPlayer, FkoPlayerWithSession, removeFkoAppointment, updateFkoPlayer} from '../../redux/slices/fkoFormData';
import {useNavigate} from 'react-router-dom';
import {FKOModal} from '../../components/FKO/FKOModal/FKOModal';
import LoaderFullPage from '../LoaderFullPage/LoaderFullPage';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {useUpdateProfile} from 'api/User/useUpdateProfile';
import {FormCheckbox} from 'components/Forms/FormCheckbox/FormCheckbox';
import {createFkoAppointments, createMboClientsForNewFkoPlayers} from 'common/fko-utils';
import {ErrorModal} from 'components/FKO/PlayersListModals/FkoPlayersListModals';
import {getCurrentAccountHolderProfileId} from 'user/user-utils';
import {logAnalyticsEvent} from 'common/analytics-events';
import {useGetSport} from 'common/useGetSport';

export const FkoSubmitButtonAndLogic = memo(({mboAppointmentNotes}: {mboAppointmentNotes?: string}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {sessionTypeName, isDbat, basePath, fkoAnalyticsPrefix} = useGetSport();
  const {updateProfile} = useUpdateProfile(true);

  const {siteId, playersArray} = useAppSelector((state) => state.fkoFormDataReducer);
  const accountHolder = playersArray.find(
    (player) => player.accountHolder && (player.mboDetails?.siteId ? player.mboDetails?.siteId === siteId : true)
  );

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [errorModalTitle, setErrorModalTitle] = useState<string | undefined>();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(!!accountHolder?.acceptedTermsOn);

  useEffect(() => {
    if (errorMessage) {
      const erroredPlayer = playersArray.find((player) => player.error);
      const eventData = {
        siteId,
        profileId: accountHolder?._id,
        accountId: accountHolder?.accountId,
        error: errorMessage,
        sessionTypeId: erroredPlayer?.selectedSession?.sessionType.id,
        staffId: erroredPlayer?.selectedSession?.staff.id,
        startDateTime: erroredPlayer?.selectedSession?.startDate,
        endDateTime: erroredPlayer?.selectedSession?.endDate,
      };
      logAnalyticsEvent(`${fkoAnalyticsPrefix}BookingFailed`, eventData);
      // TODBAT edit hotjar naming?
      // HotJar event
      if ((window as any).hj) {
        try {
          (window as any).hj('event', 'fkoBookingFailed', eventData);
        } catch (err) {
          /* ignore */
        }
      }
    }
  }, [errorMessage, accountHolder, playersArray, siteId, fkoAnalyticsPrefix]);

  const closeModal = useCallback(() => {
    setErrorMessage(undefined);
    setErrorModalTitle(undefined);
  }, []);

  const handleConfirmReservations = async () => {
    setLoading(true);

    const accountHolderProfileId = getCurrentAccountHolderProfileId();
    if (accountHolder && !accountHolder.acceptedTermsOn && accountHolderProfileId && hasAcceptedTerms) {
      try {
        updateProfile({
          profileId: accountHolderProfileId,
          update: {
            tracking: {
              acceptedTermsOn: new Date().toISOString(),
            },
          },
        });
      } catch (err) {
        //
      }
    }

    let createClientsResponse;
    try {
      createClientsResponse = await createMboClientsForNewFkoPlayers({
        accountId: accountHolder?.accountId,
        siteId,
        playersArray,
      });
    } catch (e: any) {
      console.error('Create clients failed');
      setErrorMessage(e?.message ?? 'An error occurred creating client accounts.');
      setLoading(false);
      return;
    }

    const mutablePlayers: FkoPlayer[] = playersArray.map((p) => ({...p}));

    // Update player objects with MBO data or error state.
    // Results might be an empty array, if no new MBO clients needed to be created.
    for (const createResult of createClientsResponse.results) {
      const playerId = createResult.clientSideOnlyId;
      const update: any = {};
      if (createResult.error) {
        update.error = createResult.error;
      } else {
        update.mboDetails = createResult.mbo;
        update.error = undefined; // reset any previous errors to prevent blocking subsequent appointment logic
      }

      // Dispatch player update action but also update player in players array directly to ensure that we have the
      // latest data for creating the appointments.
      const player = mutablePlayers.find((player) => player.playerId === playerId);
      if (player) {
        Object.assign(player, update);
      }
      dispatch(updateFkoPlayer({playerId, update}));
    }

    // be careful modifying this filter, as the results must conform to the type FkoPlayerWithSession[]
    const playersWithUnconfirmedAppointment = mutablePlayers.filter(
      (player) =>
        player.eligibility?.isEligible &&
        player.selectedSession &&
        player.mboDetails?.clientId &&
        !player.appointmentConfirmed &&
        !player.error
    );

    const appointmentResults = await createFkoAppointments({
      players: playersWithUnconfirmedAppointment as FkoPlayerWithSession[],
      siteId,
      mboAppointmentNotes,
    });

    for (const appointmentResult of appointmentResults) {
      // Update player object with appointmentConfirmed or error state
      const playerId = appointmentResult.playerId;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const player = mutablePlayers.find((player) => player.playerId === playerId)!;
      const update: any = {};
      if (appointmentResult.confirmed) {
        update.appointmentConfirmed = true;
        update.error = undefined;
      } else {
        update.appointmentConfirmed = false;
        update.error = appointmentResult.error;
        update.selectedSession = undefined;
      }

      if (player.selectedSession) {
        dispatch(removeFkoAppointment({session: player.selectedSession}));
      }

      Object.assign(player, update);

      dispatch(updateFkoPlayer({playerId, update}));
    }

    const anyIncompletePlayer = mutablePlayers.find(
      (player) =>
        player.eligibility?.isEligible &&
        (player.error || !player.mboDetails?.clientId || (player.selectedSession && !player.appointmentConfirmed))
    );

    setLoading(false);

    if (anyIncompletePlayer) {
      console.error('error confirming one or more bookings');
      setErrorMessage(anyIncompletePlayer.error ?? 'An unexpected error occurred.');
      if ('The selected time is not available anymore. Please select a different time.' === anyIncompletePlayer.error) {
        setErrorModalTitle('Selected time not available anymore');
      }
    } else {
      navigate(`${basePath}/confirmation`);
    }
  };

  const FormSubmitButton = () => {
    const eligibleCount = playersArray.reduce((acc, cur) => acc + (cur.eligibility?.isEligible ? 1 : 0), 0);
    const scheduledCount = playersArray.reduce(
      (acc, cur) => acc + (cur.selectedSession && !cur.appointmentConfirmed ? 1 : 0),
      0
    );

    const SubmitButtonText = (eligible: number, scheduled: number) => {
      if (!eligible) {
        return `no eligible players`;
      }
      if (!scheduled) {
        return `Select ${sessionTypeName} Time Above`;
      }
      if (eligible && scheduled) {
        return `confirm ${scheduled} reservation${scheduled > 1 ? 's' : ''}`;
      }
    };

    return (
      <FormButton
        type="submit"
        hasError={!scheduledCount || !hasAcceptedTerms}
        isPrimary
        onClick={handleConfirmReservations}
        disabled={!eligibleCount || !scheduledCount || !hasAcceptedTerms}
      >
        {SubmitButtonText(eligibleCount, scheduledCount)}
      </FormButton>
    );
  };

  return (
    <>
      <FKOModal
        isOpen={!!errorMessage}
        closeModal={closeModal}
        heading={errorModalTitle || 'Something went wrong...'}
        showFullScreen
        fixedMaxWidth
      >
        <ErrorModal errorText={errorMessage} />
      </FKOModal>

      {isLoading && (
        <LoaderFullPage
          text="Confirming your reservation"
          changeMessages={true}
          randomMessages={true}
          isDbat={isDbat}
        />
      )}

      <div className="grow flex flex-col justify-end items-center gap-4 w-full">
        {!accountHolder?.acceptedTermsOn ? (
          <>
            <FormCheckbox
              id="agreement"
              color="primary"
              checked={hasAcceptedTerms}
              onChange={() => setHasAcceptedTerms((prev) => !prev)}
              // TODBAT links tbd
              labelText={
                <span>
                  I agree to the{' '}
                  <a href="https://www.tocafootball.com/policies/waiver" target="_blank" rel="noreferrer">
                    {/* TODBAT confirm copy*/}
                    TOCA Waiver,
                  </a>{' '}
                  <a href="https://www.tocafootball.com/policies/terms-of-service" target="_blank" rel="noreferrer">
                    Terms of Service,
                  </a>{' '}
                  and{' '}
                  <a href="https://www.tocafootball.com/policies/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </span>
              }
            />
          </>
        ) : null}

        <FormSubmitButton />
      </div>
    </>
  );
});
FkoSubmitButtonAndLogic.displayName = 'FkoSubmitButtonAndLogic';
