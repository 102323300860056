import {useNavigate} from 'react-router-dom';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {LogoBanner} from 'components/Connects/LogoBanner';
import Spinner from 'components/Loader/Spinner';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {useLogout} from 'hooks/useLogout';

const Teams = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);
  const {logout} = useLogout();

  const teams = coachInfo?.teams;

  const onSelectTeam = (teamId: string) => {
    navigate(`teams/${teamId}`);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <LogoBanner />
          <div className="flex flex-col items-center">
            <div className="w-full mt-4">
              <div className="text-xl text-primary font-bold">SELECT TEAM</div>
              <div className="flex flex-wrap mt-4">
                {!teams?.length ? (
                  <div className="flex flex-col">
                    <div className="text-base">This coach is not part of any team</div>
                    <div className="text-primary text-base mt-2 cursor-pointer" onClick={() => logout()}>
                      Logout
                    </div>
                  </div>
                ) : (
                  teams?.map((team) => {
                    const teamName = team?.teamName || '';
                    return (
                      <div
                        onClick={() => onSelectTeam(team._id)}
                        key={team._id}
                        className="h-36 w-[102px] mr-2 mt-1 mb-1 rounded-md border-grey border flex flex-col items-center cursor-pointer shadow-lg"
                      >
                        <img src={team?.logoUrl} className="rounded-full mt-4 min-h-16 min-w-16 h-16 w-16" />
                        <div className="text-md mt-5">{teamName}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default Teams;
