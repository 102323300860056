import {Button} from 'components/Connects/Button';
import {Modal} from 'components/Modal/Modal';
import {teamColors} from 'constants/teamColors';
import React, {useState, useEffect} from 'react';

interface ColorSelectionProps {
  isOpen: boolean;
  activeColor: string;
  onColorSelectionHandler: (color: string) => void;
  closeModal: () => void;
}

/**
 * A color selection component that allows users to choose a team color.
 * @param activeColor - The currently active color.
 * @param onColorSelectionHandler - A callback function triggered when the color selection changes.
 */
const TeamColorSelection: React.FC<ColorSelectionProps> = ({
  isOpen = false,
  activeColor,
  onColorSelectionHandler,
  closeModal,
}: ColorSelectionProps) => {
  const [selectedColor, setSelectedColor] = useState<string>('');

  useEffect(() => {
    if (activeColor) {
      setSelectedColor(activeColor);
    }
  }, [activeColor]);

  /**
   * Handles the color selection and triggers the onColorChangeHandler callback.
   */
  const handleColorSelection = () => {
    onColorSelectionHandler(selectedColor);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} title="TEAM COLOR">
      <div className="flex flex-col items-center">
        <div className="flex flex-wrap w-96 justify-center">
          {teamColors.map((color) => (
            <div
              style={{backgroundColor: color}}
              key={color}
              className={`m-2 w-11 h-11 rounded cursor-pointer ${
                selectedColor === color ? 'border-4 border-primary' : ''
              }`}
              onClick={() => setSelectedColor(color)}
            ></div>
          ))}
        </div>
        <div className="flex items-center justify-center mt-4">
          <Button text="Confirm" color="secondary" variant="cta" onClick={handleColorSelection} />
          <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default TeamColorSelection;
