import {memo} from 'react';
import styles from './Modal.module.scss';
import {ModalContainer, ModalContainerProps} from './ModalContainer';
import {ReactComponent as CloseIcon} from '../../assets/img/icons/redesign-icons/close.svg';

export type ModalProps = ModalContainerProps & {
  title?: string;
  showHeader?: boolean;
};

export const Modal = memo(({children, isOpen, onClose, title, showHeader = false}: ModalProps) => {
  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        {showHeader && (
          <div className={styles.header}>
            {title && <h2>{title}</h2>}
            <button className={styles.closeButton} onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
        )}
        {children}
      </div>
    </ModalContainer>
  );
});
Modal.displayName = 'Modal';
