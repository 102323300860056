import {useNavigate} from 'react-router-dom';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import Spinner from 'components/Loader/Spinner';
import {Profile} from 'user/player-info.interface';
import PlayerLogo from '../../../assets/img/avatar-profile.png';
import {ReactComponent as InviteIcon} from '../../../assets/img/icons/invite-icon.svg';

const Team = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);

  const team = coachInfo?.teams[0];
  const players = team?.players as Profile[];
  const teamCoachName =
    `${currentProfile?.firstName ?? ''} ${currentProfile?.lastName ?? ''}`.trim() || currentProfile?.email || '-';
  const onClickInviteHandler = () => {
    navigate('/coach/invite');
  };

  return (
    <div className="flex-1 flex flex-col w-full justify-between">
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col items-center relative">
          <div className="pt-8">
            <div className="text-5xl text-center text-primary font-medium">TEAM</div>
            <div className="text-lg text-center text-primary border-b border-grey pl-4 pr-4 mt-4">
              COACH: {teamCoachName}
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="text-xl text-primary font-bold">PLAYERS</div>
            <div className="flex flex-wrap mt-4">
              {!players?.length ? (
                <div className="text-base">No players found</div>
              ) : (
                players?.map((player) => {
                  const playerName = player.firstName ?? '' + ' ' + player.lastName ?? '';
                  return (
                    <div
                      key={player._id}
                      className="h-36 w-[102px] mr-2 mt-1 mb-1 rounded-md border-grey border flex flex-col items-center cursor-pointer shadow-lg"
                    >
                      <img
                        src={player.avatarUrl || PlayerLogo}
                        className="rounded-full mt-4 min-h-16 min-w-16 h-16 w-16"
                      />
                      <div className="text-md mt-5">{playerName}</div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
      <div className="absolute right-6 bottom-24 cursor-pointer">
        <InviteIcon onClick={onClickInviteHandler} />
      </div>
    </div>
  );
};

export default Team;
