import {useQuery} from '@tanstack/react-query';
import {getAssessmentPlayers} from 'api/api';
import {Profile} from 'user/player-info.interface';

export const useGetAssessmentPlayers = (assessmentId: string, yearOfBirth: string, sortBy: string) => {
  return useQuery<Profile[]>({
    queryKey: ['assessment-players', assessmentId, yearOfBirth, sortBy],
    queryFn: () => getAssessmentPlayers(assessmentId, yearOfBirth, sortBy),
    enabled: !!assessmentId,
  });
};
