import {useMutation} from '@tanstack/react-query';
import {updateTeam as updateTeamApi} from 'api/api';
import {useCallback} from 'react';
import {Team} from 'user/user.interface';

export const useUpdateTeam = (onSuccessHandler: () => void, onErrorHandler: () => void) => {
  const mutateUpdateTeam = useMutation<void, unknown, Partial<Team>>({
    mutationFn: (updatedTeam) => updateTeamApi(updatedTeam).then((res: any) => res.data),
    onSuccess: onSuccessHandler,
    onError: onErrorHandler,
  });

  const updateTeam = useCallback(
    async (updatedTeam: Partial<Team>) => {
      return mutateUpdateTeam.mutate(updatedTeam);
    },
    [mutateUpdateTeam]
  );

  return {updateTeam, loading: mutateUpdateTeam.isLoading};
};
