import {useQuery} from '@tanstack/react-query';
import {Profile} from 'user/player-info.interface';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {getSelectedPlayersAssessmentPerformanceData} from 'api/api';

export type AssessmentPlayerPerformanceData = {
  playerInfo: Profile;
  performanceDetails: PlayerSessionSummary;
};

export type GetSelectedPlayerAssessmentDataParams = {playerIds: string; assessmentId: string};

//playerIds is a string of id's joined by commas
export const useGetSelectedPlayersAssessmentData = ({
  playerIds,
  assessmentId,
}: GetSelectedPlayerAssessmentDataParams) => {
  return useQuery<AssessmentPlayerPerformanceData[]>({
    queryKey: ['selected-players-assessment-data', playerIds, assessmentId],
    queryFn: () => getSelectedPlayersAssessmentPerformanceData(playerIds, assessmentId),
    enabled: !!playerIds && !!assessmentId,
  });
};
