import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getAge} from '../../user/user-utils';
import {Profile} from 'user/player-info.interface';
import {useGetSingleProgram} from '../../api/Classes/useGetSingleProgram';
// import {useGetSingleEnrollment} from 'api/Classes/useGetSingleEnrollment';
import {useGetSingleClassSchedule} from 'api/Classes/useGetSingleClassSchedule';
import {
  addClientToClass,
  AddClientToClassResponse,
  createMboClients,
  getFreeTrialClassProfileAndEligibilityInfo,
  ProgramsCheckoutResponse,
  registerToEnrollment,
  RegisterToEnrollmentParams,
} from 'api/api';
import {Helmet} from 'react-helmet';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {PageHeading} from 'components/Connects/PageHeading';
import {Button} from 'components/Connects/Button';
import {ErrorCard} from 'components/Programs/ErrorCard';
import {CheckboxAlt} from 'components/Checkbox/Checkbox';
import {PlayerCreationForm} from 'components/Programs/PlayerCreationForm';
import {ProgramCheckoutSelection} from 'components/Programs/ProgramCheckoutSelection';
import {ProgramCheckoutConfirmation} from 'components/Programs/ProgramCheckoutConfirmation';
import {ClassesCheckoutSingleSelection} from 'components/Programs/ProgramCheckoutSingleSelection';
import {ReactComponent as AvatarPlaceholder} from '../../assets/img/icons/redesign-icons/avatar-placeholder.svg';
import uuid from 'react-uuid';
import {useQueryClient} from '@tanstack/react-query';
import {isNumber} from 'lodash';
import {ClassSchedule, EnrollmentClass} from 'api/Classes/useGetPrograms';
import {getClassDisplayName} from '../../utils/programs-utils';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {CheckboxWrapper} from 'components/Checkbox/CheckboxWrapper';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {FkoPlayer, setFkoPlayersArray} from 'redux/slices/fkoFormData';
import {useGetSport} from 'common/useGetSport';

// TODO once again, this is becoming too large and complex.
export const ProgramCheckout = () => {
  const {siteId, programId, enrollmentId} = useParams();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // TODO CLASSES this should be set probably from the sport via useGetSport, HOWEVER
  // until the Enrollments/multi-select flow is patched back to functionality, DO NOT allow this to be `false`
  const isFreeTrial = true;

  // const {data: enrollment, isLoading: isLoadingEnrollment} = useGetSingleEnrollment({siteId, programId, enrollmentId});
  // TODO CLASSES fix/allow both
  const {data: enrollment, isLoading: isLoadingEnrollment} = useGetSingleClassSchedule({
    siteId,
    programId,
    classScheduleId: enrollmentId, // TODO CLASSES naming
  });
  const {data: program} = useGetSingleProgram({siteId, programId});
  // const {currentProfile, otherProfiles} = useProfiles(); // TODO CLASSES
  // const playersArray = useMemo(() => [currentProfile, ...otherProfiles], [currentProfile, otherProfiles]);
  const {basePath} = useGetSport();
  const {userData, playersArray} = useAppSelector((state) => state.fkoFormDataReducer);
  const currentProfile: FkoPlayer | Profile | undefined = playersArray?.[0];

  // TODO we're going to need to pass these profiles in to preserve the reusability of all the rest of the logic
  // without adding a stupid amount of conditionals here

  const [response, setResponse] = useState<ProgramsCheckoutResponse[] | AddClientToClassResponse[]>([]); // TODO confirm/expand types
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [playerCreationIsOpen, setPlayerCreationIsOpen] = useState(false);
  const [profileEligibility, setProfileEligibility] = useState<Record<string, {eligible: boolean; reason?: string}>>(
    {}
  );
  const [selectedSession, setSelectedSession] = useState<EnrollmentClass>();
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<string[]>([]);
  const [isPreparingProfiles, setIsPreparingProfiles] = useState(
    !!playersArray.find(
      (profile) =>
        !profile.mboDetails?.clientId && (profile.mboDetails?.siteId ? profile.mboDetails?.siteId === siteId : true)
    )
  );

  useEffect(() => {
    if (!userData) {
      navigate(basePath, {replace: true});
    }
  }, [basePath, navigate, userData]);

  // For any profiles without an associated MBO client, create one.
  useEffect(() => {
    const clientSiteIdToProfileMap = new Map<string, Profile | FkoPlayer>();
    const mboClientsToCreate = playersArray
      .filter((profile) => !profile.mboDetails?.clientId && profile.firstName && profile.lastName)
      .map((profile) => {
        const uid = uuid();
        clientSiteIdToProfileMap.set(uid, profile);
        return {
          accountHolder: profile.accountHolder,
          firstName: profile.firstName,
          lastName: profile.lastName,
          birthdate: profile.dob || '01/01/2000',
          clientSideOnlyId: uid,
        };
      });
    if (siteId && mboClientsToCreate.length) {
      if (!isFreeTrial) {
        createMboClients({siteId, clients: mboClientsToCreate}).then(() => {
          void queryClient.invalidateQueries({queryKey: ['user'], refetchType: 'all'});
          setIsPreparingProfiles(false);
        });
      } else {
        createMboClients({siteId, clients: mboClientsToCreate, isFko: true, returnProfiles: true}).then((res) => {
          if (res.profiles?.length) {
            dispatch(setFkoPlayersArray(res.profiles));
          } else {
            // TODO CLASSES probably should do something
          }
          setIsPreparingProfiles(false);
        });
      }
    }
  }, [playersArray, queryClient, siteId]);

  // TODO CLASSES do we/should we check players' schedules for already being enrolled in this?
  // Check free class trial eligibility
  useEffect(() => {
    if (isFreeTrial && !isPreparingProfiles && currentProfile?.email && enrollment?.name) {
      getFreeTrialClassProfileAndEligibilityInfo({email: currentProfile.email, classNames: [enrollment.name]}).then(
        ({data}) => {
          const profileEligibilityRecord: Record<string, {eligible: boolean; reason?: string}> = {};
          for (const profile of data.profiles || []) {
            const eligible = profile.eligibility?.isEligible || false;
            const reasonCode = profile.eligibility?.reason;
            const reason = eligible
              ? undefined
              : 'hasFutureBookedVisit' === reasonCode
              ? 'This player already has a class booked in the future.'
              : 'hasShowVisit' === reasonCode
              ? 'This player has already completed a class.'
              : 'This player is not eligible for a free trial class.';
            profileEligibilityRecord[profile._id] = {
              eligible,
              reason,
            };
          }
          setProfileEligibility(profileEligibilityRecord);

          if (currentProfile?.mboDetails?.clientId && profileEligibilityRecord[currentProfile.mboDetails.clientId]) {
            setSelectedPlayerIds([currentProfile.mboDetails.clientId]);
          }
        }
      );
    }
  }, [isFreeTrial, isPreparingProfiles, enrollment?.name, currentProfile?.email, currentProfile?.mboDetails?.clientId]);

  // Confirms both eligibility and location-match
  const isProfileEligible = useCallback(
    ({profile}: {profile: Profile | FkoPlayer}) => {
      const isEligible = profileEligibility[profile._id] === undefined || profileEligibility[profile._id].eligible;
      const playerAge = getAge(profile.dob);
      const ages = (enrollment?.ages ?? []).map((age) => Number(age));
      const ageMatches = isNumber(playerAge) && ages.includes(playerAge);
      const isSameLocation = profile.mboDetails?.siteId === siteId;
      return isEligible && isSameLocation && ageMatches;
    },
    [enrollment?.ages, profileEligibility, siteId]
  );

  const selectPlayer = useCallback((mboClientId: string) => {
    setSelectedPlayerIds((prev) =>
      prev.includes(mboClientId) ? prev.filter((selectedId) => selectedId !== mboClientId) : [...prev, mboClientId]
    );
  }, []);

  // This is used when dealing with Classes, not Enrollments
  const submitClassRequest = useCallback(
    async ({selectedClassId}: {selectedClassId?: string}) => {
      if (!siteId || !selectedPlayerIds?.length || isSubmitting || !selectedClassId) {
        console.log('submitClassRequest aborted');
        // TODO CLASSES error handling
        return;
      }

      setIsSubmitting(true);
      const playerPromises = [];
      for (const playerId of selectedPlayerIds) {
        const promise = addClientToClass({
          siteId,
          clientId: playerId,
          classId: Number(selectedClassId),
          freeTrial: isFreeTrial,
        })
          .then((res) => {
            setResponse((prev) => [...prev, res.data]);
            if (!res.data.error) {
              logAnalyticsEvent('class_booking_confirmation', {
                profileId: currentProfile?._id,
                mboSiteId: siteId,
                mboClientId: currentProfile?.mboDetails?.clientId,
                classId: selectedClassId,
                freeTrial: isFreeTrial,
              });
            }
          })
          .catch((err) => {
            setResponse((prev) => [...prev, {error: {playerId, message: err.message}}]);
          });

        playerPromises.push(promise);
      }

      Promise.all(playerPromises).finally(() => setIsSubmitting(false));
    },
    [siteId, selectedPlayerIds, isSubmitting, isFreeTrial, currentProfile?._id, currentProfile?.mboDetails?.clientId]
  );

  // This is used when dealing with Enrollments, not Classes
  const submitEnrollment = useCallback(
    async ({selectedClasses}: {selectedClasses?: (string | undefined)[]}) => {
      if (!siteId || !selectedPlayerIds?.length || isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const promises = [];
      for (const playerId of selectedPlayerIds) {
        const enrollOpenDates: string[] | undefined = selectedClasses?.length
          ? (selectedClasses.filter((_class) => _class !== undefined) as string[])
          : undefined;

        const enrollmentSubmission: RegisterToEnrollmentParams = {
          siteId,
          clientId: playerId,
          enrollmentId: Number(enrollmentId),
          enrollFromDate: !selectedClasses ? enrollment?.classes?.[0]?.startDateTime : undefined,
          enrollOpenDates,
          // test: true,
        };

        const promise = registerToEnrollment(enrollmentSubmission)
          .then((res) => {
            setResponse((prev) => [...prev, res.data]);
            if (!res.data.error) {
              logAnalyticsEvent('enrollment_booking_confirmation', {
                profileId: currentProfile?._id,
                mboSiteId: siteId,
                mboClientId: currentProfile?.mboDetails?.clientId,
                enrollmentId,
              });
            }
          })
          .catch((err) => {
            setResponse((prev) => [
              ...prev,
              {
                error: {playerId, message: err.message},
              },
            ]);
          });

        promises.push(promise);
      }

      Promise.all(promises).finally(() => setIsSubmitting(false));
    },
    [
      currentProfile?._id,
      currentProfile?.mboDetails?.clientId,
      enrollment?.classes,
      enrollmentId,
      isSubmitting,
      selectedPlayerIds,
      siteId,
    ]
  );

  // Both possible `res` types have an error property
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const responseHasError = useMemo(() => !!(response?.length && response?.find((res) => res.error)), [response]);

  const eligiblePlayers: (FkoPlayer | Profile)[] = [];
  const ineligiblePlayers: (FkoPlayer | Profile)[] = [];

  for (const profile of playersArray) {
    // don't show parent profile / profiles over 18
    // all parent profiles will be created with a 01/01/2000 birthdate without date input on landing form
    const age = getAge(profile?.dob);
    if (profile?.dob && age && age < 18) {
      if (isProfileEligible({profile})) {
        eligiblePlayers.push(profile);
      } else {
        ineligiblePlayers.push(profile);
      }
    }
  }

  if (eligiblePlayers?.length === 1 && selectedPlayerIds?.length === 0 && eligiblePlayers[0].mboDetails?.clientId) {
    selectPlayer(eligiblePlayers[0].mboDetails.clientId);
  }

  const classDisplayName = getClassDisplayName(enrollment?.name);

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Program Booking</title>
      </Helmet>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar />
        <Breadcrumbs data={{program, enrollment}} />

        {isLoadingEnrollment || isPreparingProfiles || !profileEligibility ? (
          <LoadingAnimationFullHeight />
        ) : enrollment ? (
          <>
            <div className="flex flex-col gap-8 mb-12 items-stretch sm:items-center">
              <div className="text-center">
                <PageHeading
                  text={
                    response?.length && !responseHasError && !isSubmitting ? 'Ready, Set, Play!' : `${classDisplayName}`
                  }
                />
              </div>

              {!(isSubmitting || response?.length) ? (
                <>
                  {/* Pre-Submit */}
                  <div className="mt-5 text-[1.5rem] text-secondary font-medium text-center">
                    Select the player(s) who will participate in the class
                  </div>
                  <div className="flex flex-col gap-2">
                    <ul className={`grid gap-2 ${playersArray.length > 1 ? 'lg:grid-cols-2' : 'lg:grid-cols-[400px]'}`}>
                      {/* Show eligible players first, then ineligible */}
                      {eligiblePlayers.map((profile) => (
                        <div className="flex justify-center" key={profile._id}>
                          <_EligiblePlayerCard
                            siteId={siteId}
                            profile={profile}
                            selectPlayer={selectPlayer}
                            selectedPlayers={selectedPlayerIds}
                            enrollment={enrollment}
                          />
                        </div>
                      ))}
                      {ineligiblePlayers.map((profile) => (
                        <div className="flex justify-center" key={profile._id}>
                          <_IneligiblePlayerCard
                            siteId={siteId}
                            profile={profile}
                            selectPlayer={selectPlayer}
                            selectedPlayers={selectedPlayerIds}
                            enrollment={enrollment}
                            profileEligibility={profileEligibility}
                          />
                        </div>
                      ))}
                    </ul>

                    <div
                      className={`flex flex-col gap-4 p-4 w-[clamp(300px,100%,400px)] self-center rounded-lg border ${
                        playerCreationIsOpen ? 'bg-grey-xlight border-grey-light' : 'border-[rgba(0,0,0,0)]'
                      } transition-all relative`}
                    >
                      {/* <AddPlayerButton /> */}
                      <div className="flex shrink mx-auto">
                        <Button
                          text={playerCreationIsOpen ? `Close` : 'Add Player'}
                          onClick={() => setPlayerCreationIsOpen((prev) => !prev)}
                        />
                      </div>

                      {playerCreationIsOpen && siteId && (
                        // TODO CLASSES polish
                        <PlayerCreationForm closeForm={() => setPlayerCreationIsOpen(false)} siteId={siteId} />
                      )}
                    </div>
                  </div>

                  {/* <EnrollmentCard /> */}
                  {isFreeTrial ? (
                    <ClassesCheckoutSingleSelection
                      enrollment={enrollment}
                      selectedPlayerIds={selectedPlayerIds}
                      onSubmit={submitClassRequest}
                      setCheckoutSelectedSession={setSelectedSession}
                    />
                  ) : (
                    <ProgramCheckoutSelection
                      enrollment={enrollment}
                      selectedPlayerIds={selectedPlayerIds}
                      onSubmit={submitEnrollment}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* Post-Submit / Submission-in-progress */}
                  <div className="animate-fade-in flex flex-col gap-2 max-w-[600px] md:mx-auto md:text-center">
                    {isSubmitting ? (
                      <>
                        <p className="font-poppins text-base font-semibold text-blue-dark">Confirming reservation...</p>
                      </>
                    ) : responseHasError ? (
                      <ErrorCard
                        genericWrapper
                        text="An unexpected error occurred and we were unable to complete your reservation."
                      />
                    ) : (
                      <>
                        <p className="font-poppins text-base font-semibold text-blue-dark">
                          You&apos;re registered for a free class!
                        </p>
                        <p className="font-poppins text-base text-blue-dark">
                          {`Please arrive a few minutes early and check in at the front desk.`}
                        </p>
                        <p className="font-poppins text-base text-blue-dark">
                          {`Please wear flat soccer or athletic shoes, as cleats are not allowed on the turf, as well as comfortable clothing. Oh, and don’t forget water!`}
                        </p>
                      </>
                    )}
                  </div>

                  <ProgramCheckoutConfirmation
                    enrollment={enrollment}
                    selectedPlayerIds={selectedPlayerIds}
                    playersArray={playersArray}
                    response={response}
                    selectedSessionTime={selectedSession?.startDateTime}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <div>
            {/* TODO CLASSES make better */}
            <p>Unable to load enrollment data</p>
          </div>
        )}
      </div>
    </>
  );
};
ProgramCheckout.displayName = 'ProgramCheckout';

type _PlayerCardProps = {
  siteId?: string;
  profile: Profile | FkoPlayer;
  selectPlayer: (mboClientId: string) => void;
  selectedPlayers?: string[];
  eligible?: boolean;
  enrollment?: ClassSchedule;
};
const _EligiblePlayerCard = memo(
  ({profile, selectPlayer, selectedPlayers, enrollment, eligible = true}: _PlayerCardProps) => {
    const playerAge = getAge(profile?.dob);
    // const ages = (enrollment?.ages ?? []).map((age) => Number(age));
    // const ageMatches = isNumber(playerAge) && ages.includes(playerAge);
    const playerMboId = profile.mboDetails?.clientId;
    const isSelected = playerMboId ? selectedPlayers?.includes(playerMboId) : false;

    // this shouldn't happen, but just in case
    if (!playerMboId || !eligible) {
      return (
        <_IneligiblePlayerCard
          profile={profile}
          selectPlayer={selectPlayer}
          selectedPlayers={selectedPlayers}
          enrollment={enrollment}
        />
      );
    }

    return (
      <li
        className={`grow bg-white overflow-hidden border border-grey rounded-lg flex items-center gap-4 px-4 py-2 sm:py-4 sm:min-w-[400px] max-w-[420px] shadow-flat ${
          isSelected ? 'shadow-secondary/25 border-secondary' : ''
        } transition-all`}
      >
        <CheckboxWrapper id={playerMboId} checked={isSelected} onChange={() => selectPlayer(playerMboId)}>
          {profile ? (
            <div className="flex grow flex-col">
              <p className="font-poppins font-semibold text-lg text-primary capitalize select-none">
                {profile.firstName} {profile.lastName}
              </p>
              {playerAge !== undefined ? (
                <p className="font-poppins text-sm text-grey-xdark select-none">
                  {playerAge === 1 ? `${playerAge} year old` : `${playerAge} years old`}
                </p>
              ) : null}
              {/* {!ageMatches ? (
                <p className="font-poppins text-xs text-alert select-none">
                  This player&apos;s age is not appropriate for the selected class.
                </p>
              ) : null} */}
            </div>
          ) : null}

          <div className="h-[64px] w-[64px]  min-h-[64px] min-w-[64px] border-2 border-primary rounded-full grid place-content-center overflow-hidden">
            {/* {profile.avatarUrl ? (
            <img src={profile.avatarUrl} className="rounded-full h-[56px] w-[56px]" alt="Profile image" />
          ) : (
            <AvatarPlaceholder className="fill-primary h-[56px] w-[56px] mb-1" />
          )} */}
            <AvatarPlaceholder className="fill-primary h-[56px] w-[56px] mb-1" />
          </div>
        </CheckboxWrapper>
      </li>
    );
  }
);
_EligiblePlayerCard.displayName = 'EligiblePlayerCard';

const _IneligiblePlayerCard = memo(
  ({
    siteId,
    profile,
    enrollment,
    profileEligibility,
  }: _PlayerCardProps & {profileEligibility?: Record<string, {eligible: boolean; reason?: string}>}) => {
    const playerAge = getAge(profile?.dob);
    const locationMatches = siteId && siteId === profile.mboDetails?.siteId;

    return (
      <li
        className={`grow bg-grey-xlight overflow-hidden border border-grey rounded-lg flex items-center gap-4 px-4 py-2 sm:py-4 sm:min-w-[400px] max-w-[420px] shadow-flat opacity-90 transition-all`}
      >
        <div className="opacity-20">
          <CheckboxAlt id={profile._id} disabled />
        </div>

        {profile ? (
          <div className="flex grow flex-col">
            <p className="font-poppins font-semibold text-lg text-grey-xdark capitalize select-none">
              {profile.firstName} {profile.lastName}
            </p>
            {playerAge !== undefined && (
              <p className="font-poppins text-sm text-grey-xdark select-none">
                {playerAge === 1 ? `${playerAge} year old` : `${playerAge} years old`}
              </p>
            )}

            {!locationMatches ? (
              <p className="font-poppins text-xs text-grey-xdark">
                This player profile is registered with a different center.
              </p>
            ) : isNumber(playerAge) && !(enrollment?.ages ?? []).map((age) => Number(age)).includes(playerAge) ? (
              <p className="font-poppins text-xs text-grey-xdark mt-1">
                This player&apos;s age is not appropriate for the selected class.
              </p>
            ) : (
              <p className="font-poppins text-xs text-grey-xdark">
                {profileEligibility?.[profile._id]?.reason ?? 'This player is not eligible for a free trial class.'}
              </p>
            )}
          </div>
        ) : null}

        <div className="h-[64px] w-[64px] min-h-[64px] min-w-[64px] border-2 border-primary rounded-full grid place-content-center opacity-60">
          {/* {profile.avatarUrl ? (
            <img src={profile.avatarUrl} className="rounded-full h-[56px] w-[56px] overflow-hidden" />
          ) : (
            <AvatarPlaceholder className="fill-primary h-[56px] w-[56px] mb-1" />
          )} */}
          <AvatarPlaceholder className="fill-primary h-[56px] w-[56px] mb-1" />
        </div>
      </li>
    );
  }
);
_IneligiblePlayerCard.displayName = 'IneligiblePlayerCard';
