import {Outlet} from 'react-router-dom';
import AuthContainer from '../components/AuthContainer/AuthContainer';
import {Helmet} from 'react-helmet';
import CoachAuthContainer from 'components/CoachAuthContainer/CoachAuthContainer';

export default function RootCoachLayout() {
  return (
    <>
      <Helmet>
        <title>MyTOCA | Coach View</title>
      </Helmet>
      <AuthContainer>
        <CoachAuthContainer>
          <Outlet />
        </CoachAuthContainer>
      </AuthContainer>
    </>
  );
}
