import {DateTime} from 'luxon';
import {AppointmentCalendar} from '../redux/slices/fkoFormData';
import {DayOfWeek, SavedBookingPreferences} from '../user/player-info.interface';
import {DEFAULT_FKO_BOOKING_WINDOW, TocaLocation} from '../constants/locations';
import {BookingSession} from '../redux/slices/booking';
import {BOOKABLE_SESSION_TYPES} from '../constants/bookingConsts';

//****Resolve function for API calls****
interface ResolveTypes {
  data: any;
  error: any;
}

// TODO eval
export const resolve = async (promise: any) => {
  const resolved: ResolveTypes = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await promise;
  } catch (e) {
    resolved.error = e;
  }

  return resolved;
};

export function getUrlSearchParams() {
  return new URL(document.location.href).searchParams;
}
// TODO could probably replace these with useSearchParams from react router.
export function getUrlSearchParam(name: string) {
  return getUrlSearchParams().get(name);
}

export const formatPhoneNumber = (phone: string) => {
  return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

// TODO need a global solution to this
export const isDateValid = (dateStr: string) => !isNaN(new Date(dateStr.replace(/-/g, '/')).getDate());

export const constructDateFromHyphenated = (dateStr?: string) => new Date(dateStr?.replace(/-/g, '/') ?? '');

export function currentDatePlusDays(numDays: number) {
  const dt = new Date();
  dt.setDate(dt.getDate() + numDays);
  return dt;
}

export function currentDatePlusWeeks(numWeeks: number) {
  const dt = new Date();
  dt.setDate(dt.getDate() + numWeeks * 7);
  return dt;
}

const getLuxonDate = (dateStr?: string) => {
  return dateStr ? DateTime.fromISO(dateStr, {setZone: true}) : DateTime.now();
};

/**
 * Parses the string but keeps the resulting date in a fixed-offset timezone with the
 * provided offset in the string.
 */
export const parseDateTime = (date?: string | DateTime) => {
  // if no date use current moment, if string convert to DateTime, if DateTime continue
  const luxonDateTime = !date ? getLuxonDate() : typeof date === 'string' ? getLuxonDate(date) : date;

  const zoneStrings = new Map([
    ['UTC-7', 'PT'],
    ['UTC-6', 'MT'],
    ['UTC-5', 'CT'],
    ['UTC-4', 'ET'],
  ]);

  return {
    dateStr: luxonDateTime.toFormat('yyyy-MM-dd'),
    day: luxonDateTime.toFormat('d'),
    dayStrLong: luxonDateTime.toFormat('cccc'),
    dayStrShort: luxonDateTime.toFormat('ccc'),
    monthStr: luxonDateTime.toFormat('LLL'),
    time: luxonDateTime.toFormat('t'),
    year: luxonDateTime.toFormat('yyyy'),
    zone: zoneStrings.get(luxonDateTime.toFormat('ZZZZ')) || '',
    iso: luxonDateTime.toISOTime(),
    bareIsoTime: luxonDateTime.toISOTime({suppressMilliseconds: true, includeOffset: false}),
  };
};
export type ParseDateTimeReturn = ReturnType<typeof parseDateTime>;

/**
 * this calculates whether a date is in DST mathematically,
 *  to avoid relying on a user's local date.getTimezoneOffset()
 */
export const isDaylightSavings = (date: string) => {
  const d = new Date(date);
  const [dow, day, month] = [d.getDay(), d.getDate(), d.getMonth()];

  //January, february, and december are out.
  if (month < 2 || month > 10) {
    return false;
  }
  //April to October are in
  if (month > 2 && month < 10) {
    return true;
  }
  const previousSunday = day - dow;
  //In march, we are DST if our previous sunday was on or after the 8th.
  if (month == 2) {
    return previousSunday >= 8;
  }
  //In november we must be before the first sunday to be dst.
  //That means the previous sunday must be before the 1st.
  return previousSunday <= 0;
};

export const getAdjustedISODate = (date: string, location: TocaLocation) => {
  const offset = isDaylightSavings(date) ? location.dstOffset : location.utcOffset;
  //todo this is only reliable for single digit negative offsets...
  return `${date.replace(/(\.\d+)([+-]\d{2}:\d{2})/, '')}.000-0${Math.abs(offset)}:00`;
};

export type CreateDateMap = {
  startDate?: string;
  daysAhead?: number;
};

export const createDateMap = ({startDate, daysAhead}: CreateDateMap) => {
  let count = daysAhead ?? DEFAULT_FKO_BOOKING_WINDOW;
  let date = getLuxonDate(startDate);
  const map: AppointmentCalendar = {};

  do {
    const dateInfo = parseDateTime(date);
    map[dateInfo.dateStr] = {
      ...dateInfo,
      appointments: [],
      fetching: false,
      hasFetched: false,
    };

    date = date.plus({days: 1});
    count--;
  } while (count > 0);

  return map;
};

export const getDatesInRange = ({
  startDate,
  endDate,
  format,
}: {
  startDate?: string;
  endDate?: string;
  format?: string;
}) => {
  let curDt = startDate ? getLuxonDate(startDate) : getLuxonDate();
  const endDt = endDate ? getLuxonDate(endDate) : curDt.plus({days: 14});
  const dates: string[] = [];
  while (curDt.startOf('day') <= endDt.startOf('day')) {
    dates.push(curDt.toFormat(format || 'yyyy-MM-dd'));
    curDt = curDt.plus({days: 1});
  }
  return dates;
};

export const getWeeklyDatesInclusive = ({
  startDate,
  numWeeks,
  format,
}: {
  startDate?: string;
  numWeeks: number;
  format?: string;
}) => {
  let curDt = startDate ? getLuxonDate(startDate) : getLuxonDate();
  const endDt = curDt.plus({weeks: numWeeks});
  const dates: string[] = [];

  while (curDt.startOf('day') < endDt.startOf('day')) {
    dates.push(curDt.toFormat(format || 'yyyy-MM-dd'));
    curDt = curDt.plus({weeks: 1});
  }
  return dates;
};

export const getNextWeekDay = ({startDate, day}: {startDate?: string; day: DayOfWeek}) => {
  let curDt = startDate ? getLuxonDate(startDate) : getLuxonDate();

  for (let i = 0; i < 8; i++) {
    if (curDt.toFormat('EEE') !== day || curDt.toMillis() < getLuxonDate().toMillis()) {
      curDt = curDt.plus({days: 1});
    }
  }

  return curDt.toFormat('yyyy-MM-dd');
};

/**
 * Invokes/Executes the reCaptcha v3 challenge and resolves the token that needs to be sent to the backend for
 * verification.
 *
 * @param recaptchaSiteKey The public reCaptcha site key.
 * @return {Promise}
 */
export async function executeRecaptchaV3(recaptchaSiteKey: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const grecaptcha = (window as any).grecaptcha;
    if (!grecaptcha) {
      return reject('Recaptcha script not loaded, "grecaptcha" is undefined.');
    }
    grecaptcha.ready(() => {
      grecaptcha.execute(recaptchaSiteKey, {action: 'submit'}).then((token: string) => {
        resolve(token);
      });
    });
  });
}

//returns a string of up to 2 initials for a name.
export const parseInitials = (firstName?: string, lastName?: string) => {
  if (!firstName) {
    return '';
  } else if (lastName) {
    return `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;
  } else {
    const letters = firstName.split(' ').map((name) => name.charAt(0).toUpperCase());
    return `${letters[0]}${letters.pop() ?? ''}`;
  }
};

export const createPlaceholderSessionFromPreferences = ({
  preferences,
  location,
  startDate,
}: {
  preferences: SavedBookingPreferences;
  location: TocaLocation;
  startDate?: string;
}): BookingSession => {
  const {day, coach, startTime, endTime, sessionType} = preferences;
  const date = startDate ? getNextWeekDay({startDate, day}) : getNextWeekDay({day});
  const correctedStartDate = getAdjustedISODate(`${date}T${startTime}`, location);
  const correctedEndDate = getAdjustedISODate(`${date}T${endTime}`, location);

  return {
    id: '999999',
    startDate: correctedStartDate,
    endDate: correctedEndDate,
    sessionType: sessionType ?? {id: -1, name: BOOKABLE_SESSION_TYPES[0]},
    staff: {id: coach, firstName: '', lastName: ''},
    siteId: '',
    location: {
      id: parseInt(location.siteId),
      name: location.name,
      address: '',
      address2: '',
      phone: '',
    },
    dateStr: 'date',
    bookingStatus: {
      unavailableTime: true,
    },
  };
};

export function isTvMode(path?: string) {
  return (path ?? window.location.pathname).startsWith('/tv/');
}

export function sortObjectsByStringProperty(arr: any[], stringProp: string) {
  return arr.sort((a, b) => {
    const valueA = a[stringProp].toUpperCase();
    const valueB = b[stringProp].toUpperCase();
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}
