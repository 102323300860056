import {ReactNode, memo, useCallback, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import styles from './Navbar.module.scss';
import {useProfiles} from '../../api/User/useProfiles';
import {ProfileModal} from 'components/ProfileModal/ProfileModal';
import {ReactComponent as HomeIcon} from '../../assets/img/icons/menu-home-icon.svg';
import {ReactComponent as CalendarIcon} from '../../assets/img/icons/menu-calendar-icon.svg';
import {ReactComponent as ProfileIcon} from '../../assets/img/icons/menu-profile-icon.svg';
import {ReactComponent as TrophyIcon} from '../../assets/img/icons/menu-challenge-icon.svg';
import {ReactComponent as LogoutIcon} from '../../assets/img/icons/redesign-icons/logout.svg';
import {ReactComponent as InviteIcon} from '../../assets/img/icons/menu-invite-icon.svg';
import {ReactComponent as TeamIcon} from '../../assets/img/icons/menu-team-icon.svg';
import {locationAllowsBookings} from '../../feature-flags/booking-locations';
import {Profile} from 'user/player-info.interface';

export type NavElement = {
  id?: string;
  icon: ReactNode;
  title: string;
  destination: string;
};

export const Navbar = memo(() => {
  const {teamId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {currentProfile} = useProfiles();
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setProfileModalOpen(false);
  }, []);

  const toggleModal = useCallback(() => {
    setProfileModalOpen((prev) => !prev);
  }, []);

  const coreNavItems: NavElement[] = [
    {
      id: 'home-nav-item',
      icon: <HomeIcon />,
      title: 'Home',
      destination: '/home',
    },
    {
      id: 'leaderboards-nav-item',
      icon: <TrophyIcon />,
      title: 'Leaderboard',
      destination: '/leaderboards',
    },
  ];

  const withBookingsNavItems: NavElement[] = [
    coreNavItems[0],
    coreNavItems[1],
    {
      id: 'book-nav-item',
      icon: <CalendarIcon />,
      title: 'My Bookings',
      destination: '/mybookings',
    },
  ];

  const trainerNavItems: NavElement[] = [
    {
      id: 'home-nav-item',
      icon: <HomeIcon />,
      title: 'Home',
      destination: '/trainer',
    },
    {
      id: 'logout-item',
      icon: <LogoutIcon />,
      title: 'Log Out',
      destination: '/logout',
    },
    // {
    //   id: 'book-nav-item',
    //   icon: <CalendarIcon />,
    //   title: 'Bookings',
    //   destination: '/mybookings',
    // },
  ];

  const coachNavItems: NavElement[] = [
    {
      id: 'home-nav-item',
      icon: <HomeIcon />,
      title: 'Home',
      destination: '/coach',
    },
    {
      id: 'team-nav-item',
      icon: <TeamIcon />,
      title: 'Team',
      destination: `/coach/teams/${teamId}/players`,
    },
    {
      id: 'invite-nav-item',
      icon: <InviteIcon />,
      title: 'Invite',
      destination: `/coach/teams/${teamId}/invite`,
    },
  ];

  const isTrainer = !!currentProfile.role?.includes('trainer');
  const isCoach = !!currentProfile.role?.includes('coach');

  function getNavItems(isTrainer: boolean, isCoach: boolean, currentProfile: Profile) {
    if (isTrainer) {
      return trainerNavItems;
    } else if (isCoach) {
      return coachNavItems;
    } else if (locationAllowsBookings(currentProfile.location?.siteId)) {
      return withBookingsNavItems;
    } else {
      return coreNavItems;
    }
  }

  const navItems = getNavItems(isTrainer, isCoach, currentProfile);

  if (!currentProfile || !navItems) {
    return null;
  }

  return (
    <>
      <div className={`${styles.container} shadow-below-heavy exceed-global-padding`}>
        <div className={styles.buttonContainer}>
          {navItems.map((item, i) => (
            <NavButton
              onClick={() => {
                closeModal();
                navigate(item.destination);
              }}
              id={item.id}
              icon={item.icon}
              text={item.title}
              key={`navbtn${i}`}
              active={!profileModalOpen && location.pathname === item.destination}
            />
          ))}
          {!isTrainer ? (
            <NavButton icon={<ProfileIcon />} text="Profile" onClick={toggleModal} active={profileModalOpen} />
          ) : null}
        </div>
      </div>
      <ProfileModal isOpen={profileModalOpen} setClosed={closeModal} />
    </>
  );
});
Navbar.displayName = 'Navbar';

export interface NavButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  active?: boolean;
  dark?: boolean;
  text: string;
}

const NavButton = memo((props: NavButtonProps) => {
  const {icon, text, active, ...rest} = props;

  return (
    <button className={`${styles.navButton} ${active ? styles.active : ''}`} {...rest}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{text}</div>
    </button>
  );
});
NavButton.displayName = 'NavButton';
