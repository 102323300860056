import {useState, useEffect, useRef} from 'react';
import {ReactComponent as ArrowDown} from '../../assets/img/icons/arrow-down-select.svg';

export interface DropdownOption {
  displayValue: string;
  value: string;
}

interface DropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  onSelect: (option: DropdownOption) => void;
  defaultOption?: DropdownOption | null;
}

const Dropdown = ({options, placeholder = 'Select an option', onSelect, defaultOption}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [defaultOption]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left w-full" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-sm border border-grey-dark px-4 py-2 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:border-secondary focus:text-secondary"
          onClick={handleToggle}
        >
          {selectedOption ? selectedOption.displayValue : placeholder}
          <ArrowDown />
        </button>
      </div>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-full rounded-sm bg-primary focus:outline-none z-50"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="max-h-40 py-1 w-full h-full overflow-y-scroll" role="none">
            {options.map((option, index) => (
              <button
                key={index}
                className="text-white block w-full text-left px-4 py-2 text-sm hover:bg-blue"
                role="menuitem"
                onClick={() => handleOptionClick(option)}
              >
                {option.displayValue}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
