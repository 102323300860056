import {useQuery} from '@tanstack/react-query';
import {getAssessmentById} from 'api/api';
import {Assessment} from './useGetCoachInfo';

export const useGetAssessment = (assessmentId: string) => {
  return useQuery<Assessment>({
    queryKey: ['assessment', assessmentId],
    queryFn: () => getAssessmentById(assessmentId),
    enabled: !!assessmentId,
  });
};
