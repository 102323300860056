import {Team} from 'user/user.interface';
import {ReactComponent as EditIcon} from '../../assets/img/icons/edit-icon-minimal.svg';
import {useNavigate} from 'react-router-dom';

export const TeamProfile = ({teamInfo}: {teamInfo: Team | undefined}) => {
  const navigate = useNavigate();

  if (!teamInfo) {
    return null;
  }
  const teamName = teamInfo?.teamName;
  const profileBarBackgroundColor = teamInfo?.teamColor || 'var(--theme-primary)';

  const onClickEditTeam = () => {
    navigate(`/coach/teams/${teamInfo?._id}/edit`);
  };

  return (
    <div
      className="exceed-global-padding pt-3 pb-3 pr-5 pl-5 grid grid-cols-[auto_1fr] gap-5"
      style={{backgroundColor: profileBarBackgroundColor}}
    >
      <img src={teamInfo.logoUrl} alt={`${teamName} logo`} className="h-14 w-14 min-w-14 rounded-full" />
      <div className="min-w-0 flex flex-col justify-center space-y-3">
        <p className="text-white font-semibold text-lg leading-lg flex items-center">
          {teamName}
          <EditIcon className="fill-white h-3 w-3 ml-2 cursor-pointer" onClick={onClickEditTeam} />
        </p>
      </div>
    </div>
  );
};
