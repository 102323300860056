import {Outlet, useLocation, useParams} from 'react-router-dom';
import {Navbar} from '../components/Navbar/Navbar';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {useProfiles} from 'api/User/useProfiles';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {TeamProfile} from 'components/TeamProfile/TeamProfile';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import Spinner from 'components/Loader/Spinner';
import {useMemo} from 'react';

export default function CoachNavbarLayout() {
  const {teamId} = useParams();
  const location = useLocation();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);

  const teamInfo = useMemo(() => coachInfo?.teams.find((team) => team._id === teamId), [teamId, coachInfo?.teams]);

  // Check if the current route is team edit page
  const isTeamEdit = location.pathname.includes('team/edit');

  return (
    <PageContainer>
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {!isTeamEdit ? (
            <>
              <LogoBanner />
              <TeamProfile teamInfo={teamInfo} />
            </>
          ) : null}

          <Outlet />
          <Navbar />
        </>
      )}
    </PageContainer>
  );
}
