import {useSportContext} from 'context/SportContext';
import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

export const playerAssessmentSessionTypeName = 'FC Dallas Player Assessment';

export const PlayerAssessmentSportWrapper = () => {
  const {sport, setSport} = useSportContext();

  useEffect(() => {
    if (sport !== 'PLAYER_ASSESSMENT') {
      setSport({sport: 'PLAYER_ASSESSMENT'});
    }
  }, [setSport, sport]);

  return <Outlet />;
};
