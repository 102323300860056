import {PropsWithChildren, useEffect} from 'react';
import {useAuthStatus} from '../../firebase/useAuthStatus.hook';
import Splash from '../Splash/Splash';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetUser} from '../../api/User/useGetUser';
import {auth as fireBaseAuth} from '../../firebase/config';
import {isAdminUser, isCoach, isCoachUser, isTrainer, isTrainerUser, setPostLoginUri} from '../../user/user-utils';
import {useProfiles} from '../../api/User/useProfiles';

const noEmailVerificationRequiredPaths = ['/programs'];

/**
 * Renders children only if there is an authenticated user and user details are available.
 */
export default function AuthContainer({children}: PropsWithChildren) {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {loggedIn, checkingStatus: isCheckingAuthStatus} = useAuthStatus();
  const {isLoading: isUserLoading, data: user} = useGetUser();
  const {currentProfile} = useProfiles();

  const firebaseUser = fireBaseAuth.currentUser;
  const _isAdmin = isAdminUser(user);
  const _isTrainer = isTrainerUser(user);
  const _isCoach = isCoachUser(user);

  const emailVerificationRequired =
    firebaseUser &&
    !firebaseUser.emailVerified &&
    !noEmailVerificationRequiredPaths.some((path) => pathname.startsWith(path));

  useEffect(() => {
    if ((!loggedIn && !isCheckingAuthStatus) || (!isUserLoading && !user)) {
      if (window.location.pathname !== '/') {
        // Store the original requested URI in local storage so that we can redirect the user there after login
        const requestedUri = window.location.pathname + window.location.search;
        setPostLoginUri(requestedUri);
      }
      navigate('/auth');
    } else {
      if (_isAdmin && !window.location.pathname.startsWith('/admin')) {
        console.debug('Detected admin. Redirecting to /admin');
        navigate('/admin');
      } else if (_isTrainer && isTrainer(currentProfile) && !window.location.pathname.startsWith('/trainer')) {
        console.debug('Detected trainer. Redirecting to /trainer');
        navigate('/trainer');
      } else if (_isCoach && isCoach(currentProfile) && !window.location.pathname.startsWith('/coach')) {
        console.debug('Detected coach. Redirecting to /coach');
        navigate('/coach');
      }
    }
  }, [
    pathname,
    loggedIn,
    isCheckingAuthStatus,
    isUserLoading,
    user,
    currentProfile,
    navigate,
    _isAdmin,
    _isTrainer,
    _isCoach,
  ]);

  useEffect(() => {
    if (emailVerificationRequired) {
      if (window.location.pathname !== '/') {
        const requestedUri = window.location.pathname + window.location.search;
        setPostLoginUri(requestedUri);
      }
      navigate('/verify-email');
    }
  }, [navigate, emailVerificationRequired]);

  if (isCheckingAuthStatus || isUserLoading) {
    return <Splash />;
  }

  if (!user || !firebaseUser || emailVerificationRequired) {
    return null;
  }

  if (_isAdmin || _isTrainer) {
    console.debug(`[AuthContainer] Skipping user completeness check for ${_isAdmin ? 'admin' : 'trainer'} user`);
    return <>{children}</>;
  }

  // Removed UserCompletenessCheck component for now since our user flows have changed significantly and the user check
  // is no longer appropriate in all situations (e.g. when a new user is trying to book a first session/class).
  // return <UserCompletenessCheck>{children}</UserCompletenessCheck>;
  return <>{children}</>;
}
