import {useParams} from 'react-router-dom';
import QRCode from 'qrcode.react';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {ReactComponent as ShareIcon} from '../../../assets/img/icons/share-icon.svg';
import {toast} from 'react-toastify';
import {roleType} from 'constants/constants';
import {useMemo} from 'react';

const Invite = () => {
  const {teamId} = useParams();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);

  const teamInfo = useMemo(() => coachInfo?.teams.find((team) => team._id === teamId), [teamId, coachInfo?.teams]);

  const inviteLink = `${window.location.origin}/team-onboarding?teamId=${teamId}&role=${roleType.player}&locationId=${teamInfo?.siteId}`;

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    toast.success('Invite link copied to clipboard');
  };

  const handleInviteLinkShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Player invite link!',
          url: 'inviteLink',
        });
      } catch (error) {
        copyInviteLink();
      }
    } else {
      copyInviteLink();
    }
  };

  return (
    <div className="flex-1 flex flex-col w-full justify-between">
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col items-center">
              <div className="text-5xl text-primary leading-[60px] font-semibold mt-10">INVITE TO JOIN</div>
              <QRCode className="mt-10" value={inviteLink} size={196} />
              <div className="mt-11 text-sm">Please scan the bar code to access the team invitation to join!!</div>
            </div>
          </div>
          <div className="exceed-global-padding p-6  border-2 border-grey">
            <Button type="submit" color="secondary" variant="cta" onClick={handleInviteLinkShare}>
              <div className="flex items-start">
                <span>Share</span>
                <ShareIcon className="text-white  fill-white h-6 w-6 cursor-pointer ml-2" />
              </div>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Invite;
