import {useQuery} from '@tanstack/react-query';
import {getAssessmentStatsById} from 'api/api';

export interface AssessmentStats {
  completedSessions: number;
  assignedSessions: number;
  totalPlayers: number;
}

export const useGetAssessmentStats = (assessmentId: string) => {
  return useQuery<AssessmentStats>({
    queryKey: ['assessment-stats', assessmentId],
    queryFn: () => getAssessmentStatsById(assessmentId),
    enabled: !!assessmentId,
  });
};
