import {useGetTeamPublicInfo} from 'api/User/useGetTeamPublicInfo';
import tocaNewLogo from '../../assets/img/my-toca-new-logo.svg';
import {useSearchParams} from 'react-router-dom';
import {Button} from 'components/Connects/Button';
import TeamOnboardingSignUpForm from './TeamOnboardingSignUpForm';
import styles from './TeamOnboardingSignUp.module.scss';

const TeamOnboardingSignUp = () => {
  const [searchParams] = useSearchParams();
  const [teamId, role] = [searchParams.get('teamId') ?? '', searchParams.get('role') ?? ''];
  const {data: teamPublicInfo} = useGetTeamPublicInfo(teamId);

  return (
    <div className="flex flex-col justify-between min-h-screen overflow-y-scroll">
      <div className="flex flex-col items-center pl-20 pr-20">
        <img className="mt-10" src={tocaNewLogo} height={12} width={80} />
        <img className="w-24 h-24 rounded-full border border-grey mt-10" src={teamPublicInfo?.logoUrl} />
        <div className="text-white text-base font-semibold mt-2">{teamPublicInfo?.teamName}</div>
        <div className="text-xs text-white text-center mt-2">
          Please sign up and register for your player assessment at the UMB Bank Performance Center
        </div>
      </div>

      <div className={`flex-1 flex flex-col justify-end`}>
        <div className={`pl-5 pr-5 w-full relative ${styles.halfTransparentHalfWhite}`}>
          <div className="rounded-md p-5 mt-8 bg-white relative z-20 border border-grey">
            <div className="text-primary font-semibold text-lg text-center mb-4">{role.toUpperCase()} REGISTRATION</div>
            <TeamOnboardingSignUpForm />
          </div>
        </div>
        <div className="bg-white pt-4 pb-4 pl-16 pr-16">
          <Button text="SIGN UP" color="primary" variant="cta" />
        </div>
      </div>
    </div>
  );
};

export default TeamOnboardingSignUp;
