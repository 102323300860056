import {Workbook, Worksheet} from 'exceljs';
import {saveAs} from 'file-saver';
import {has} from 'lodash';
import {format} from 'date-fns';
import {Assessment} from 'api/Coach/useGetCoachInfo';
import {AssessmentPlayerPerformanceData} from 'api/Coach/useGetSelectedPlayersAssessmentData';
import {tocaExcelReportLogo} from 'assets/img/tocaExcelLogo';
import {TocaLocation, getLocationBySiteId} from 'constants/locations';
import {Team} from 'user/user.interface';

/**
 * Converts a date string to a formatted date string.
 *
 * @param dateString - The date string in 'YYYY-MM-DD' format.
 * @returns The formatted date string in 'Month Day, Year' format.
 *
 * Example:
 * Input: '2024-07-04'
 * Output: 'July 4, 2024'
 *
 * Example:
 * Input: '2024-03-30'
 * Output: 'March 30, 2024'
 */
export function convertToFormattedDate(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
}

/**
 * Converts a date range from "YYYY-MM-DD" format to "M/D" format.
 *
 * @param startDate - The start date in "YYYY-MM-DD" format.
 * @param endDate - The end date in "YYYY-MM-DD" format.
 * @returns The date range in "M/D-M/D" format.
 */
export function convertDateRange(startDate: string, endDate: string): string {
  if (!startDate || !endDate) {
    return '';
  }

  // Parse the input date strings into Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Helper function to format a Date object as "M/D"
  const formatDate = (date: Date) => `${date.getMonth() + 1}/${date.getDate()}`;

  // Combine the formatted start and end dates into the desired range format
  return `${formatDate(start)}-${formatDate(end)}`;
}

/**
 * Splits an array into chunks of a specified size, optionally skipping the first few elements.
 *
 * @param array - The array to split.
 * @param chunkSize - The size of each chunk.
 * @param skip - The number of elements to skip at the beginning of the array.
 * @returns An array of chunks.
 */
function splitArrayIntoChunks<T>(array: T[], chunkSize: number, skip = 0): T[][] {
  const result: T[][] = [];
  for (let i = skip; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

// Predefined columns for the Excel sheet
const excelCols = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
];

/**
 * Creates a merged header cell in the worksheet.
 */
const createMergedHeader = (
  worksheet: Worksheet,
  startCol: string,
  endCol: string,
  value: string,
  bgColor: string,
  textColor: string
): void => {
  worksheet.mergeCells(`${startCol}6:${endCol}6`);
  const cell = worksheet.getCell(`${startCol}6`);
  cell.border = {left: {style: 'thin'}, right: {style: 'thin'}};
  cell.value = value;
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: bgColor},
  };
  cell.font = {color: {argb: textColor}, bold: true};
  cell.alignment = {horizontal: 'center', vertical: 'middle'};
};

/**
 * Creates a header cell with specified formatting in the worksheet.
 *
 * @param worksheet - The worksheet object.
 * @param col - The column of the cell.
 * @param row - The row of the cell.
 * @param value - The value to be placed in the cell.
 * @param bgColor - The background color of the cell.
 * @param textColor - The text color of the cell.
 */
const createHeaderCell = (
  worksheet: Worksheet,
  col: string,
  row: number,
  value: string,
  bgColor: string,
  textColor: string,
  leftBorder = false,
  rightBorder = false
): void => {
  const cell = worksheet.getCell(`${col}${row}`);
  cell.value = value;
  // Add borders
  if (leftBorder) {
    cell.border = {left: {style: 'thin'}};
  } else if (rightBorder) {
    cell.border = {right: {style: 'thin'}};
  }
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: bgColor},
  };
  cell.font = {color: {argb: textColor}, bold: true};
};

const getFormattedDate = (startDate: string, endDate: string): string => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Extract the year, month, and date

  const startDateMonth = startDateObj.getMonth() + 1; // (Months are zero-based, so add 1)
  const startDateDay = startDateObj.getDate();

  const endDateYear = endDateObj.getFullYear();
  const endDateMonth = endDateObj.getMonth() + 1; // (Months are zero-based, so add 1)
  const endDateDay = endDateObj.getDate();

  const formattedDate = `${startDateDay}/${startDateMonth}-${endDateDay}/${endDateMonth}/${endDateYear}`;
  return formattedDate;
};

/**
 * Creates the heading section of the assessment in the worksheet.
 *
 * @param worksheet - The worksheet object.
 * @param assessment - The assessment data.
 * @param team - The team data.
 * @param locationInfo - The location information.
 */
const createAssessmentHeading = (
  worksheet: Worksheet,
  assessment: Assessment,
  team: Team,
  locationInfo: TocaLocation
): void => {
  const assessmentHeadingData: [string, string | undefined][] = [
    ['Event', assessment.name],
    ['Club', team.teamName],
    ['Date', getFormattedDate(assessment.startDate, assessment.endDate)],
    ['Time', `${assessment.startTime} - ${assessment.endTime}`],
    ['Location', locationInfo?.name],
  ];

  assessmentHeadingData.forEach((row, index) => {
    const rowIndex = index + 1;
    worksheet.getCell(`A${rowIndex}`).value = row[0];
    worksheet.getCell(`B${rowIndex}`).value = row[1];
    worksheet.getCell(`A${rowIndex}`).font = {bold: true};
  });
};

interface ExerciseObj {
  [exerciseName: string]: {
    streak: number;
    accuracy: number;
    speedOfPlay: number;
    mph: number;
  };
}

interface TransformedPlayersExerciseData {
  [playerId: string]: ExerciseObj;
}

const transformPlayerPerformanceData = (
  playersAssessmentData: AssessmentPlayerPerformanceData[]
): {transformedPlayerPerformanceData: TransformedPlayersExerciseData; exerciseNames: string[]} => {
  const transformedPlayerPerformanceData: TransformedPlayersExerciseData = {};
  let exerciseNamesSet: Set<string> = new Set();

  playersAssessmentData.forEach((player) => {
    const exercisesObj: ExerciseObj = {};
    const currentPlayerExerciseNamesSet: Set<string> = new Set();
    const playerId = player.playerInfo._id;

    const isExerciseConfirmedByTrainer = player.performanceDetails?.exerciseSummary?.find((exercise) =>
      has(exercise, 'confirmedBy')
    );

    player.performanceDetails?.exerciseSummary?.forEach((exercise) => {
      //add unique exercise names to set
      const exerciseName = exercise.name;
      if (!currentPlayerExerciseNamesSet.has(exerciseName)) {
        currentPlayerExerciseNamesSet.add(exerciseName);
      }

      if (isExerciseConfirmedByTrainer) {
        if (has(exercise, 'confirmedBy')) {
          exercisesObj[exerciseName] = {...exercise};
        }
      } else {
        exercisesObj[exerciseName] = {...exercise};
      }
    });

    // Update exercise names set if the current player has more exercises
    if (currentPlayerExerciseNamesSet.size > exerciseNamesSet.size) {
      exerciseNamesSet = currentPlayerExerciseNamesSet;
    }
    transformedPlayerPerformanceData[playerId] = exercisesObj;
  });

  return {transformedPlayerPerformanceData, exerciseNames: Array.from(exerciseNamesSet)};
};

/**
 * Downloads performance data as an Excel file.
 *
 * @param playersPerformanceData - The performance data of the players.
 * @param assessment - The assessment data.
 * @param team - The team data.
 */
export const downloadPerformanceDataAsExcel = async (
  playersPerformanceData: AssessmentPlayerPerformanceData[],
  assessment: Assessment,
  team: Team
): Promise<void> => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');
  const locationInfo = getLocationBySiteId(assessment.siteId);
  const exerciseHeadings = ['Streak', 'Accuracy', 'SOP', 'Pace(MPH)'];
  const {transformedPlayerPerformanceData, exerciseNames} = transformPlayerPerformanceData(playersPerformanceData);

  // Create the assessment heading section
  createAssessmentHeading(worksheet, assessment, team, locationInfo as TocaLocation);

  // Merge cells and add the logo image
  worksheet.mergeCells('E2:H4');
  const imageId = workbook.addImage({
    base64: tocaExcelReportLogo.replace(/^data:image\/png;base64,/, ''),
    extension: 'png',
  });
  worksheet.addImage(imageId, 'E2:H4');

  // Define headers for rows 6 and 7
  const headersRow6 = ['Player', 'Club', 'Team', 'Time', 'Exercise Name', '', '', ''];
  ['A', 'B', 'C', 'D'].forEach((col, index) => {
    worksheet.mergeCells(`${col}6:${col}7`);
    createHeaderCell(worksheet, col, 6, headersRow6[index], 'FF0000FF', 'FFFFFFFF');
  });

  const exerciseCols = splitArrayIntoChunks(excelCols, 4, 4);

  // Create headers for each exercise
  exerciseCols.forEach((exerciseCol, index) => {
    createMergedHeader(worksheet, exerciseCol[0], exerciseCol[3], exerciseNames[index], 'FF0000FF', 'FFFFFFFF');
    exerciseCol.forEach((col, index) => {
      createHeaderCell(worksheet, col, 7, exerciseHeadings[index], 'FF0000FF', 'FFFFFFFF', index === 0, index === 3);
    });
  });

  // Fill in player performance data
  let exerciseValuesStartRowIndex = 8;
  playersPerformanceData.forEach((player) => {
    const {playerInfo} = player;

    let sessionTime;
    if (player.performanceDetails?.startTime) {
      const date = new Date(player.performanceDetails?.startTime);
      sessionTime = format(date, 'HH:mm');
    }

    //set player name
    worksheet.getCell(`A${exerciseValuesStartRowIndex}`).value = `${playerInfo?.firstName} ${playerInfo?.lastName}`;
    //set club name
    worksheet.getCell(`B${exerciseValuesStartRowIndex}`).value = team.teamName;
    //set team name
    worksheet.getCell(`C${exerciseValuesStartRowIndex}`).value = team.teamName;
    //set time
    worksheet.getCell(`D${exerciseValuesStartRowIndex}`).value = sessionTime;

    // Set exercise data
    const exercises = exerciseNames.map((exerciseName) => {
      const exerciseData = transformedPlayerPerformanceData?.[playerInfo._id]?.[exerciseName];
      return exerciseData;
    });

    exerciseCols.forEach((exerciseCol, exerciseIndex) => {
      const streakCell = worksheet.getCell(`${exerciseCol[0]}${exerciseValuesStartRowIndex}`);
      const accuracyCell = worksheet.getCell(`${exerciseCol[1]}${exerciseValuesStartRowIndex}`);
      const sopCell = worksheet.getCell(`${exerciseCol[2]}${exerciseValuesStartRowIndex}`);
      const paceCell = worksheet.getCell(`${exerciseCol[3]}${exerciseValuesStartRowIndex}`);

      streakCell.value = exercises?.[exerciseIndex]?.streak;
      streakCell.border = {left: {style: 'thin'}}; // Add left border to the first cell

      accuracyCell.value = exercises?.[exerciseIndex]?.accuracy;

      const sopValue = exercises?.[exerciseIndex]?.speedOfPlay;
      sopCell.value = sopValue && sopValue < 99999 ? sopValue : '-';

      paceCell.value = exercises?.[exerciseIndex]?.mph;
      paceCell.border = {right: {style: 'thin'}}; // Add right border to the last cell
    });
    exerciseValuesStartRowIndex++;
  });

  // Save the workbook to a buffer and trigger download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  saveAs(blob, 'Event_Assessment.xlsx');
};

export const generateAgeGroupOptions = () => {
  const currentYear = new Date().getFullYear();
  const playerMinAge = 8;
  const ageGroupOptions = [];
  const defaultOption = {displayValue: 'Select All', value: ''};
  for (let i = 0; i <= 10; i++) {
    const year = currentYear - playerMinAge - i;
    ageGroupOptions.push({
      value: `${year}`,
      displayValue: `U-${playerMinAge + i}`,
    });
  }
  return [defaultOption, ...ageGroupOptions.reverse()];
};
