import {memo} from 'react';
import styles from './LoadingAnimation.module.scss';
import Spinner from './Spinner';
import {ModalContainer} from 'components/Modal/ModalContainer';

export const LoadingAnimation = memo(() => {
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
});
LoadingAnimation.displayName = 'LoadingAnimation';

export const LoadingAnimationFullHeight = memo(() => {
  return (
    <div className={`${styles.container} ${styles.fullHeight}`}>
      <Spinner />
    </div>
  );
});
LoadingAnimationFullHeight.displayName = 'LoadingAnimationFullHeight';

export const LoadingAnimationOverlay = memo(({isLoading}: {isLoading: boolean}) => {
  return (
    <ModalContainer isOpen={isLoading}>
      <div className={`${styles.container} ${styles.fullHeight}`}>
        <Spinner darkMode />
      </div>
    </ModalContainer>
  );
});
LoadingAnimationOverlay.displayName = 'LoadingAnimationModal';
