import styles from './MyBookings.module.scss';
import {useQueryClient} from '@tanstack/react-query';
import {memo, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {cancelAppointment} from '../../../api/api';
import {useProfiles} from '../../../api/User/useProfiles';
import {SessionCard} from '../../../components/Booking/SessionCard/SessionCard';
import {
  BookingSession,
  resetBookingSessions,
  resetInitializedSiteId,
  setBookingCompleted,
} from '../../../redux/slices/booking';
import {BookingFormButton} from '../../../components/Buttons/Form/FormButton';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import {Helmet} from 'react-helmet';
import {logAnalyticsEvent} from '../../../common/analytics-events';
// import {useRestrictions} from '../../../hooks/useRestrictions';
// import {useGetMboClientStatus} from '../../../hooks/useGetMboClientStatus';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {Modal} from 'components/Modal/Modal';
import {ModalBookingSession} from 'components/Booking/ModalBookingSession/ModalBookingSession';
import {locationAllowsBookings} from '../../../feature-flags/booking-locations';
import {FutureVisit, generateVisitsQueryKey, useGetMboVisits, Visit} from 'api/Booking/useGetMboVisits';
import {parseVisits} from 'utils/booking-utils';

export const MyBookings = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const {currentProfile} = useProfiles();
  // const {restrictions} = useRestrictions();
  const [visitToCancel, setVisitToCancel] = useState<FutureVisit>();
  const [errorModal, setErrorModal] = useState<string>();
  const [viewState, setViewState] = useState('default');
  const {bookingCompleted} = useAppSelector((state) => state.bookingReducer.bookingCreation);
  // const {isActive} = useGetMboClientStatus();

  const {clientId, siteId} = currentProfile.mboDetails ?? {};
  const {data: visits, isFetching} = useGetMboVisits({clientId, siteId});

  const {pastVisits, futureVisits, recentVisits} = parseVisits({visits, limit: 3});

  useEffect(() => {
    if (!locationAllowsBookings(currentProfile?.mboDetails?.siteId)) {
      navigate('/home');
    }
  }, [currentProfile, navigate]);

  useEffect(() => {
    if (bookingCompleted) {
      dispatch(setBookingCompleted(false));
      dispatch(resetBookingSessions());
      dispatch(resetInitializedSiteId());
    }
  }, [bookingCompleted, dispatch]);

  const cancelVisit = async (visit: Visit) => {
    try {
      const remainingVisits = visits?.filter((v) => v.AppointmentId !== visit.AppointmentId) || [];
      const visitsQueryKey = generateVisitsQueryKey({clientId: currentProfile.mboDetails?.clientId});

      await queryClient.cancelQueries({queryKey: visitsQueryKey}); // cancel any still-pending queries
      queryClient.setQueryData(visitsQueryKey, remainingVisits); // optimistic update
      setVisitToCancel(undefined);

      // TODO should handle errors here and revert optimistic updates on failure
      await cancelAppointment(visit.AppointmentId, clientId, siteId);

      void queryClient.invalidateQueries({queryKey: visitsQueryKey});
      logAnalyticsEvent('booking_cancelled', {
        appointmentId: visit.AppointmentId,
        mboClientId: currentProfile.mboDetails?.clientId,
        mboSiteId: currentProfile.mboDetails?.siteId,
      });
    } catch (err) {
      console.debug('unable to cancel appointment, (error, visit): ', err, visit);
      setVisitToCancel(undefined);
      setErrorModal('An error occurred and we were unable to cancel this visit.');
    }
  };

  // const openCancelModal = useCallback((visit: FutureVisit) => {
  //   setVisitToCancel(visit);
  // }, []);
  const closeCancelModal = useCallback(() => {
    setVisitToCancel(undefined);
  }, []);
  // const navigateToBookings = useCallback(() => navigate('/bookings'), [navigate]);
  const returnToDefaultView = useCallback(() => {
    window.scrollTo(0, 0);
    setViewState('default');
  }, []);
  const showAllPastSessions = useCallback(() => {
    window.scrollTo(0, 0);
    setViewState('pastFull');
  }, []);

  const convertVisitToSession = useCallback(
    (visit: Visit): BookingSession => ({
      id: visit.Id.toString(),
      startDate: visit.StartDateTime,
      endDate: visit.EndDateTime,
      sessionType: {id: 12345, name: visit.Name},
      staff: {id: visit.StaffId, firstName: '', lastName: ''},
      location: {id: visit.LocationId, name: '', address: '', address2: '', phone: ''},
    }),
    []
  );

  if (isFetching && !visits) {
    return <LoadingAnimationFullHeight />;
  }

  return (
    <>
      <Helmet>
        <title>MyTOCA | My Bookings</title>
      </Helmet>

      <div className="lg:max-w-[900px] lg:w-full lg:mx-auto lg:px-8 lg:border-x border-solid border-grey-light pb-12">
        {viewState === 'default' ? (
          <>
            <PageHeading text="My Bookings" />

            {/* NOTE: The "Book now" button is hidden for now to avoid confusion with other bookings such as classes. */}
            {/*<div className={styles.sessionSection}>*/}
            {/*  {isActive(currentProfile.mboDetails?.clientId) && !restrictions.bookings.readOnly ? (*/}
            {/*    <div className="mx-auto max-w-[600px] w-full">*/}
            {/*      <BookingFormButton variant="primary" text="Book now" onClick={navigateToBookings} />*/}
            {/*    </div>*/}
            {/*  ) : null}*/}
            {/*</div>*/}

            <section className={styles.sessionSection}>
              <PageSubHeading text="upcoming" />
              {futureVisits.length ? (
                <ul>
                  {futureVisits.map((visit, i) => (
                    <li key={`conf-session-card${i}`} className={styles.sessionCardWrapper}>
                      <SessionCard displayOnly session={convertVisitToSession(visit)} />
                      {/* Cancel ability is disabled for now as per business request */}
                      {/*{!restrictions.bookings.readOnly && (*/}
                      {/*  <button className={styles.cancelButton} onClick={() => openCancelModal(visit)}>*/}
                      {/*    <CancelIcon />*/}
                      {/*  </button>*/}
                      {/*)}*/}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={styles.placeholderText}>No upcoming visits.</p>
              )}
            </section>

            <section className={styles.sessionSection}>
              <PageSubHeading text="past month" />
              {recentVisits.length ? (
                <ul>
                  {recentVisits.map((visit, i) => (
                    <li key={`conf-session-card${i}`} className={styles.sessionCardWrapper}>
                      <SessionCard disabled session={convertVisitToSession(visit)} />
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={styles.placeholderText}>No past visits found.</p>
              )}
            </section>

            {pastVisits?.length > recentVisits?.length ? (
              <BookingFormButton onClick={showAllPastSessions} text="Show all past sessions" />
            ) : null}

            {/* Cancel Visit Confirmation Modal */}
            {visitToCancel && (
              <Modal isOpen onClose={closeCancelModal} title="Cancel Session">
                <div className={styles.cancelSessionContainer}>
                  <ModalBookingSession session={convertVisitToSession(visitToCancel)} />

                  {visitToCancel.cancellable ? (
                    <>
                      <p className={styles.alertText}>Are you sure you want to cancel the above session?</p>
                      <BookingFormButton onClick={closeCancelModal}>No, keep session</BookingFormButton>
                      <BookingFormButton onClick={() => cancelVisit(visitToCancel)} variant="danger">
                        Yes, cancel session
                      </BookingFormButton>
                    </>
                  ) : (
                    <>
                      <p className={styles.alertText}>
                        <span>Please call us to cancel this session.</span>
                      </p>
                      <p className={styles.alertText}>
                        Note: If you cancel less than 24 hours before your session, you will be charged in full.
                      </p>
                      <p className={styles.contactText}>
                        <span>Center:</span>
                        {visitToCancel.location?.name}
                      </p>
                      <p className={styles.contactText}>
                        <span>Phone:</span>
                        {visitToCancel.location?.phoneNumber}
                      </p>
                      <BookingFormButton onClick={closeCancelModal}>Close</BookingFormButton>
                    </>
                  )}
                </div>
              </Modal>
            )}

            {/* Error Modal */}
            {!visitToCancel && errorModal && (
              <Modal isOpen onClose={() => setErrorModal('')}>
                <p className={styles.warningText}>{errorModal}</p>
                <BookingFormButton isPrimary onClick={() => setErrorModal('')}>
                  close
                </BookingFormButton>
              </Modal>
            )}
          </>
        ) : (
          <>
            <PageHeading text="My Bookings" onClickOverride={returnToDefaultView} />
            <section className={styles.sessionSection}>
              <PageSubHeading text="all past visits" />
              {pastVisits.length ? (
                <ul>
                  {pastVisits.map((visit, i) => (
                    <li key={`conf-session-card${i}`} className={styles.sessionCardWrapper}>
                      <SessionCard disabled session={convertVisitToSession(visit)} />
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No past visits found.</p>
              )}
            </section>
          </>
        )}
      </div>
    </>
  );
});
MyBookings.displayName = 'MyBookings';
