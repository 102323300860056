import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {User} from '../../user/user.interface';
import {ProfileUpdate, updateProfileApi, updateProfileFkoApi} from '../api';
import {Profile} from '../../user/player-info.interface';
import {merge} from 'lodash';
import {AxiosError} from 'axios';

export const useUpdateProfile = (isFko = false) => {
  const queryClient = useQueryClient();

  // TODO there are errors here where somehow this is expecting an incorrect return type.
  // updateProfileApi returns a <ApiProfile> but that doesn't actually work here...
  const mutateProfile = useMutation<User, AxiosError, ProfileUpdate>({
    mutationFn: isFko ? updateProfileFkoApi : updateProfileApi,
    onMutate: async (profileUpdate) => {
      await queryClient.cancelQueries({queryKey: ['user', 'profiles']});
      const prev = queryClient.getQueryData<Profile[]>(['user', 'profiles']) || [];

      // Optimistic client-side profile update (need to map array + profile objects to indicate a change occurred)
      const profiles = (queryClient.getQueryData<Profile[]>(['user', 'profiles']) || []).map((p) => ({...p}));
      const updatedProfile = profiles?.find((p) => p._id === profileUpdate.profileId);
      if (updatedProfile) {
        merge(updatedProfile, profileUpdate.update);
        queryClient.setQueryData(['user', 'profiles'], profiles);
      }

      return {prev, profileUpdate};
    },
    onError: (error, _, context) => {
      //@ts-expect-error : context type isn't correctly inferred, this bug is fixed in TS 4.7
      queryClient.setQueryData(['user', 'profiles'], context?.prev);
      console.error('error updating user', error);
    },
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['user', 'profiles']}),
  });

  const updateProfile = useCallback(
    async (profileUpdate: ProfileUpdate) => {
      return mutateProfile.mutate(profileUpdate);
    },
    [mutateProfile]
  );

  return {updateProfile};
};
