import {combineReducers} from 'redux';

import bookingReducer from './slices/booking';
import fkoFormDataReducer from './slices/fkoFormData';
import loadingReducer from './slices/loading';
import profileReducer from './slices/profile';
import coachReducer from './slices/coach';

const appReducer = combineReducers({
  bookingReducer,
  fkoFormDataReducer,
  loadingReducer,
  profileReducer,
  coachReducer,
});

// This for clearing redux store when user logout
const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
