import {
  assessmentCAStatesAndLocationsList,
  assessmentUSStatesAndLocationsList,
  classesCAStatesAndLocationsList,
  classesUSStatesAndLocationsList,
  dbatCAStatesAndLocationsList,
  dbatUSStatesAndLocationsList,
  tocaCAStatesAndLocationsList,
  tocaUSStatesAndLocationsList,
} from 'constants/locations';
import {FormOptionGroup} from 'components/Forms/FormSelect/FormSelect';
import {countryStates} from 'common/country-states';
import {useGetSport} from 'common/useGetSport';
import {useMemo} from 'react';

/**
 * Returns preformatted location options for FKO dropdowns, specific to the current sport.
 */
export const useGetLocationFormOptionGroups = () => {
  // TODO re-eval with the inclusion of Classes
  const {sport} = useGetSport();

  const locationFormOptionGroups = useMemo(
    () =>
      sport === 'dbat'
        ? dbatLocationFormOptionGroups
        : sport === 'playerAssessment'
        ? playerAssessmentLocationFormOptionGroups
        : sport === 'classes'
        ? classesLocationFormOptionGroups
        : tocaLocationFormOptionGroups,
    [sport]
  );

  return {locationFormOptionGroups};
};

export const getLocationFormOptionGroupsForStates = (
  statesAndLocationsList: typeof tocaUSStatesAndLocationsList,
  countryCode: string,
  countryName: string
) => {
  if (!statesAndLocationsList) {
    return [];
  }
  return statesAndLocationsList.reduce((optionGroups, stateObj) => {
    optionGroups.push({
      label: `${countryStates[countryCode][stateObj.state]}, ${countryName}`,
      options: stateObj.locations
        // .filter((loc) => loc.fkoSupported)
        .map((loc) => ({
          id: loc.siteId,
          value: loc.siteId,
          displayValue: loc.name,
        })),
    });
    return optionGroups;
  }, [] as FormOptionGroup[]);
};

// List US states first
const tocaLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(tocaUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(tocaCAStatesAndLocationsList, 'CA', 'Canada'),
];
const dbatLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(dbatUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(dbatCAStatesAndLocationsList, 'CA', 'Canada'),
];
const playerAssessmentLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(assessmentUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(assessmentCAStatesAndLocationsList, 'CA', 'Canada'),
];
export const classesLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(classesUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(classesCAStatesAndLocationsList, 'CA', 'Canada'),
];
