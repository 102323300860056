import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useGetAssessmentPlayers} from 'api/Coach/useGetAssessmentPlayers';
import {Assessment as AssessmentProps, useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {CheckboxAlt} from 'components/Checkbox/Checkbox';
import {Button} from 'components/Connects/Button';
import Spinner from 'components/Loader/Spinner';
import {ReactComponent as EyeIcon} from '../../../assets/img/icons/eye.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/img/icons/delete.svg';
// import ExportToExcelModal from '../PlayerAssessmentData/ExportToExcelModal';
import Dropdown, {DropdownOption} from 'components/Dropdown/Dropdown';
import {deletePlayerFromAssessment, getSelectedPlayersAssessmentPerformanceData} from 'api/api';
import {downloadPerformanceDataAsExcel, generateAgeGroupOptions} from '../utils';
import {Team} from 'user/user.interface';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

const ageGroupOptions = generateAgeGroupOptions();

const sortByOptions = [
  {displayValue: 'Highest Accuracy', value: 'highest-accuracy'},
  {displayValue: 'Fastest SOP', value: 'fastest-sop'},
  {displayValue: 'Highest Accuracy + SOP', value: 'highest-accuracy-and-fastest-sop'},
];

const Assessment = ({assessment}: {assessment: AssessmentProps | null}) => {
  const navigate = useNavigate();
  const {teamId} = useParams();
  // const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showDeletePlayerModal, setShowDeletePlayerModal] = useState<boolean>(false);
  const [playerToBeDeleted, setPlayerToBeDeleted] = useState<string>('');
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [ageGroup, setAgeGroup] = useState<DropdownOption | null>(null);
  const [sortBy, setSortBy] = useState<DropdownOption | null>(null);
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading: isCoachLoading} = useGetCoachInfo(currentProfile?._id);
  const {data: players, isLoading: isPlayersLoading} = useGetAssessmentPlayers(
    assessment?._id ?? '',
    ageGroup?.value ?? '',
    sortBy?.value ?? ''
  );

  const togglePlayerSelection = useCallback((playerId: string) => {
    setSelectedPlayers((prevSelected) =>
      prevSelected.includes(playerId) ? prevSelected.filter((id) => id !== playerId) : [...prevSelected, playerId]
    );
  }, []);

  const onClickDeletePlayerHandler = useCallback((playerId: string) => {
    setPlayerToBeDeleted(playerId);
    setShowDeletePlayerModal(true);
  }, []);

  const onClickDeletePlayerConfirm = useCallback(async () => {
    try {
      // delete player
      await deletePlayerFromAssessment(assessment?._id ?? '', playerToBeDeleted);
      toast.success('Player deleted successfully.');
      setShowDeletePlayerModal(false);
      setPlayerToBeDeleted('');
    } catch {
      toast.error('Failed to delete player.');
    }
  }, [playerToBeDeleted, assessment?._id]);

  const setSelectAllPlayers = useCallback(() => {
    const playerIds = players?.map((player) => player._id) ?? [];
    setSelectedPlayers(playerIds.length === selectedPlayers.length ? [] : playerIds);
  }, [players, selectedPlayers.length]);

  const onClickViewPlayerAssessmentData = useCallback(
    (playerId: string) => {
      const url = `/coach/teams/${teamId}/playerAssessmentData/?assessmentId=${assessment?._id}&playerId=${playerId}`;
      navigate(url);
    },
    [assessment?._id, teamId, navigate]
  );

  const onClickExportHandler = async () => {
    try {
      const playersPerformanceData = await getSelectedPlayersAssessmentPerformanceData(
        selectedPlayers.join(','),
        assessment?._id || '',
        sortBy?.value || ''
      );
      downloadPerformanceDataAsExcel(
        playersPerformanceData,
        assessment as AssessmentProps,
        coachInfo?.teams?.[0] as Team
      );
      toast.success('Players performance data exported successfully.');
    } catch {
      toast.error('Failed to export players performance data.');
    }
    // setShowExportModal(false);
  };

  // reset selected players when players are updated
  useEffect(() => {
    if (players?.length) {
      setSelectedPlayers([]);
    }
  }, [players]);

  if (isCoachLoading || isPlayersLoading) {
    return (
      <div className="w-full h-full flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col h-full w-full justify-between">
      <ConfirmationModal
        isOpen={showDeletePlayerModal}
        message="Are you sure you want to delete player from assessment ?"
        onConfirm={onClickDeletePlayerConfirm}
        onCancel={() => setShowDeletePlayerModal(false)}
      />
      {/* <ExportToExcelModal
        isOpen={showExportModal}
        closeModal={() => setShowExportModal(false)}
        onClickExportHandler={onClickExportHandler}
      /> */}
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col">
          <div className="flex items-center justify-between mt-5">
            <span className="text-base text-primary font-semibold">{assessment?.name}</span>
            <div className="flex items-center">
              {players?.length ? (
                <>
                  <CheckboxAlt
                    id="selectall"
                    checked={players?.length === selectedPlayers.length}
                    onChange={setSelectAllPlayers}
                  />
                  <p className="ml-2 text-xs">Select All</p>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex mt-4 space-x-4">
            <div className="flex-grow">
              <Dropdown placeholder="Sort By" defaultOption={sortBy} options={sortByOptions} onSelect={setSortBy} />
            </div>
            <div className="flex-grow"></div>
            <div className="flex-grow">
              <Dropdown
                placeholder="Age Group"
                defaultOption={ageGroup}
                options={ageGroupOptions}
                onSelect={setAgeGroup}
              />{' '}
            </div>
          </div>

          <div className="flex-1 bg-white rounded-md mt-5 shadow-lg">
            {players?.length ? (
              players?.map((player) => (
                <div key={player._id} className="flex items-center mt-5 pb-5 pl-6 border-b border-grey">
                  <div className="flex items-center justify-between">
                    <CheckboxAlt
                      id={player._id}
                      checked={selectedPlayers.includes(player._id)}
                      onChange={() => togglePlayerSelection(player._id)}
                    />
                    <div className="text-grey-xdark text-sm leading-lg ml-2">
                      {(player.firstName ?? '') + ' ' + (player.lastName ?? '')}
                    </div>
                  </div>
                  <div className="ml-auto flex items-center mr-6">
                    <EyeIcon
                      className="mr-4 cursor-pointer"
                      onClick={() => onClickViewPlayerAssessmentData(player._id)}
                    />
                    <DeleteIcon className="cursor-pointer" onClick={() => onClickDeletePlayerHandler(player?._id)} />
                  </div>
                </div>
              ))
            ) : (
              <p className="mt-4 text-center text-sm">No players found</p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4 flex-none">
        <Button
          disabled={!selectedPlayers.length}
          type="submit"
          color="secondary"
          variant="cta"
          text="EXPORT TO EXCEL"
          onClick={onClickExportHandler}
        />
      </div>
    </div>
  );
};

export default Assessment;
