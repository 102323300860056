import {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {convertDateRange, downloadPerformanceDataAsExcel} from '../utils';
import {useGetAssessment} from 'api/Coach/useGetAssessment';
import {PlayerAssessmentConnectsBaselineSessionData} from 'components/Connects/PlayerAssessmentBaselineSessionData/PlayerAssessmentConnectsBaselineSessionData';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {
  AssessmentPlayerPerformanceData,
  useGetSelectedPlayersAssessmentData,
} from 'api/Coach/useGetSelectedPlayersAssessmentData';
import {Assessment, useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {Team} from 'user/user.interface';
import {useProfiles} from 'api/User/useProfiles';

const PlayerAssessmentData = () => {
  const [searchParams] = useSearchParams();
  const {currentProfile} = useProfiles();
  const {data: coachInfo} = useGetCoachInfo(currentProfile?._id);
  const [playerId, assessmentId] = [searchParams.get('playerId') ?? '', searchParams.get('assessmentId') ?? ''];
  const {data: assessmentInfo} = useGetAssessment(assessmentId);
  const {data: playersAssessmentData, isLoading} = useGetSelectedPlayersAssessmentData({
    playerIds: playerId,
    assessmentId,
  });

  const date = convertDateRange(assessmentInfo?.startDate ?? '', assessmentInfo?.endDate ?? '');
  const playerAssessmentData = playersAssessmentData?.[0]?.performanceDetails;

  const isSessionSelectionCompleted = useMemo(
    () =>
      playerAssessmentData?.exerciseSummary?.find((exercise) => {
        return 'confirmedBy' in exercise;
      }),
    [playerAssessmentData]
  );

  const onClickDownloadHandler = async () => {
    downloadPerformanceDataAsExcel(
      playersAssessmentData as AssessmentPlayerPerformanceData[],
      assessmentInfo as Assessment,
      coachInfo?.teams[0] as Team
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : playerAssessmentData ? (
        <div className="grow flex flex-col">
          <div className="text-5xl text-primary text-center leading-[60px] font-semibold mt-10">
            PLAYER ASSESSMENT DATA
          </div>

          {playerAssessmentData?.isBaseline && isSessionSelectionCompleted ? (
            <>
              <div className="exceed-global-padding lg:max-w-[800px] lg:mx-auto lg:w-full">
                <PlayerAssessmentConnectsBaselineSessionData
                  sessionSummary={playerAssessmentData}
                  enableSessionSelection={false}
                  date={date}
                />
              </div>
              <div className="exceed-global-padding p-6 mt-2  border-2 border-grey" onClick={onClickDownloadHandler}>
                <Button color="secondary" variant="cta" text="Download XLS" />
              </div>
            </>
          ) : (
            <div className="grid place-content-center">
              <p className="text-sm text-grey-xdark">Player assessment data not found</p>
            </div>
          )}
        </div>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">unable to load player assessment data</p>
        </div>
      )}
    </>
  );
};

export default PlayerAssessmentData;
